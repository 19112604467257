import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'siconv-toggle',
  template: `<div (click)="toggle()" class="collapse-container">
    <span [title]="title" *ngIf="show"><i class="toggle fa fa-caret-down"></i></span>
    <span [title]="title" *ngIf="!show"><i class="toggle fa fa-caret-right"></i></span>
</div>
`,
  styles: [`.toggle{color:#0a6aa6}`]
})
export class ToggleComponent implements OnInit {
  @Input() show: boolean;
  @Output() expanded = new EventEmitter<boolean>();
  @Input() title = 'Expandir/Contrair';

  constructor() { }

  ngOnInit() {
  }

  public toggle(): void {
    this.show = !this.show;
    this.expanded.emit(this.show);
  }

}
