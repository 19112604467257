import { Pipe, PipeTransform  } from '@angular/core';

import { User } from '../model/user.model';
import { Profile } from '../model/profile.model';

@Pipe({
  name: 'userProfiles'
})
export class UserProfilesPipe implements PipeTransform {
  
  transform (user: User): string {
    return (!user || !user.profiles) ? undefined : user.profiles.map((profile: Profile) => profile.name).join(', ');
  }

}