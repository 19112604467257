import { NgModule } from '@angular/core';
import { PickListComponent } from './components/picklist.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule, 
    FormsModule
  ],
  declarations: [
    PickListComponent,
  ],
  exports: [
    PickListComponent
  ]
})
export class SiconvPickListModule {}
