import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XML from 'xml-js';
declare var require: any
const XML_EXTENSION = '.xml';

@Injectable()
export class SiconvXMLService {

  constructor() { }

  public exportAsXMLFile(json: any[], fileName: string): void {
    //console.log(json);
     var result = XML.js2xml(json, { compact: true, spaces: 4 });
     this.saveAsXMLFile(result, fileName);
     console.log(result);
  }

  private saveAsXMLFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: 'application/xml'});

     if (fileName != null && fileName != undefined && fileName != "") {
      fileName = fileName + '_' + new Date().getTime();
    } else {
      fileName = "" + new Date().getTime();
    } 

     FileSaver.saveAs(data, fileName + XML_EXTENSION);
  }
}