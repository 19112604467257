import { CardModel } from '../model/card.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiconCardService {

  _columns: CardModel[] = [];
  _dados: any[];

  get columns(): any  {
    return this._columns;
  }
  set columns(cols: any ) {
    this._columns = cols;
  }
  get dados(): any  {
    return this._dados;
  }
  set dados(data: any ) {
    this._dados = data;
  }
 
  constructor() { }
}
