import { 
  Component, OnInit, Input, ViewChild, ElementRef, Inject, SimpleChanges,
  SimpleChange, Renderer2, forwardRef,
  AfterViewInit, OnChanges, Injector, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

import { DisabledDates } from '../../model/disabled-dates.model';
import { DateService } from '../../services/date.service';
import { WindowService } from '../../../common/services/window.service';

import moment from 'moment';
import { Logs } from 'selenium-webdriver';

@Component({
  selector: 'siconv-date-picker',
  template: `<ng-container *ngIf="inline">
  <div class="form-group" [siconvHasError]="ngControl.control">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>  
</ng-container>

<ng-container *ngIf="!inline">
  <div class="form-group" [siconvHasError]="ngControl.control">
    <label *ngIf="label" class="control-label" [class.obrigatorio]="required">{{label}}</label>
    <ng-container *ngTemplateOutlet="template"></ng-container>
    <div class="description">{{description}}</div> 
  </div>
</ng-container>

<ng-template #template>
  <div class="input-group">
    <span 
      class="fa fa-exclamation-circle form-control-icon"
      *ngIf="ngControl.control.invalid && (ngControl.control.dirty || ngControl.control.touched)">
    </span>

    <span 
      class="fa fa-check form-control-icon"
      *ngIf="ngControl.control.valid && (ngControl.control.dirty || ngControl.control.touched)">
    </span>

    <div *ngIf="!datedisabled" [class]="inputGroupClass" #divContainer>
            
      <input type="text" #dateInput
        class="form-control input-date"
        [style.border-color]="ngControl.control.invalid && (ngControl.control.dirty || ngControl.control.touched) ? '#D32F2F' : ''"        
        [siconvHasError]="ngControl.control"         
        (input)="onInput($event.target.value)"
        (blur)="onBlur()"
        [siconvInputMask]="getMask()"
        [value]="formattedValue">

      <span class="input-group-addon">
        <span tooltip="Selecionar data do calendÃ¡rio" container="body">
          <span class="material-icons date-picker-icon"
            [style.font-size]="iconSize"
            (click)="toggle()">
            <img src="./assets/img/calendar-alt-regular.png" width="18px" height="18px" />
          </span>
        </span>
      </span>
    </div>

    <div *ngIf="datedisabled" [class]="inputGroupClass" #divContainer>
            
      <input type="text" #dateInput
        class="form-control input-date"
        [style.border-color]="ngControl.control.invalid && (ngControl.control.dirty || ngControl.control.touched) ? '#D32F2F' : ''"        
        [siconvHasError]="ngControl.control" 
        disabled
        (input)="onInput($event.target.value)"
        (blur)="onBlur()"
        [siconvInputMask]="getMask()"
        [value]="formattedValue">

      <span class="input-group-addon">
        <span tooltip="Selecionar data do calendÃ¡rio" container="body">
          <span class="material-icons date-picker-icon disabled"
            [style.font-size]="iconSize"
            (click)="toggle()">
            <img src="./assets/img/calendar-alt-regular.png" width="18px" height="18px" />
          </span>
        </span>
      </span>
    </div>

    <div *ngIf="showCalendar" class="date-picker-container"
        [class.up]="direction === 'up'"
        [class.down]="direction === 'down'">
      <datepicker [(ngModel)]="value"
        name="datePicker"
        (selectionDone)="selectionDone($event)"
        [dateDisabled]="disabledDates"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [showWeeks]="showWeeks"
        formatDayHeader="ddd"
        formatMonth="MMM">
      </datepicker>
    </div>
  </div>
</ng-template>
`,
  styles: [`.date-picker-icon{cursor:pointer;border:0!important;margin:0!important}.date-picker-icon.disabled{cursor:not-allowed}.input-group-addon{border:0!important;padding:0 10px!important;background-color:transparent!important;line-height:1}.input-date{border-top-right-radius:5px!important;border-bottom-right-radius:5px!important}.date-picker-container{font-size:13px;width:275px}input.ng-invalid.ng-touched{border-color:#d32f2f!important}.form-group .form-control-icon{width:7rem}`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DatePickerComponent)
  }]
})
export class DatePickerComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  private _required: boolean;

  @Input() datedisabled: boolean = false;

  private modelValue: Date; // data selecionada
  private viewValue: string; // valor do input

  get value(): Date {
    return this.modelValue;
  }

  @Input('value')
  set value(value: Date) {
    this.modelValue = value;
    this.onChange(value);
    this.onTouched();
  }

  get formattedValue(): string {
    return this.viewValue ? this.viewValue : '';
  }

  set formattedValue(viewValue: string) {
    this.viewValue = viewValue;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: Date): void {
    this.viewValue = this.dateService.format(value, this.format);
    this.modelValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ... ControlValueAccessor methods

  @Input() direction: string = 'down';
  @Input() format: string = 'DD/MM/YYYY';
  @Input() inline: boolean = false;
  @Input() iconSize: string = '32px';
  @Input() inputGroupClass: string = 'input-group date';
  @Input() label: string = '';
  @Input() outsideClick: boolean = false;
  @Input() description: string = '';

  @Input()
  set required(value: boolean | string) {
    this._required = ! (value === false  || value === 'false');
  }
  get required() {
    return this._required;
  }

  @Input() showWeeks: boolean = false;

  @Input('siconvDisabledDays') disabledDays: Date[] = [];
  @Input('siconvDisabledMonths') disabledMonths: Date[] = [];
  @Input('siconvDisabledYears') disabledYears: Date[] = [];
  @Input('siconvMaxDate') maxDate: Date;
  @Input('siconvMinDate') minDate: Date;

  ngControl: NgControl;
  disabledDates: DisabledDates;
  showCalendar: boolean = false;

  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('divContainer') divContainer: ElementRef;

  constructor(
    private injector: Injector,
    private renderer: Renderer2,
    private dateService: DateService,
    @Inject(DOCUMENT) private document: any,
    private windowService: WindowService,
  ) { }

  public ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.iconSize = this.inline ? '24px' : '32px';
  }

  public ngAfterViewInit(): void {
    this._setValidators();
    if (this.outsideClick) {
      this._handleOutsideClick();
    }
  }

  getMask() {
    if (this.format === 'DD/MM/YYYY') {
      return '99/99/9999';
    }
    if (this.format === 'MM/YYYY') {
      return '99/9999';
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // days, months or years disabled change
    const disabledDays: Date[] = this._getDisabledDays(changes);
    const disabledMonths: Date[] = this._getDisabledMonths(changes);
    const disabledYears: Date[] = this._getDisabledYears(changes);
    if (disabledDays || disabledMonths || disabledYears) {
      this.disabledDates = this.dateService.buildDisabledDates(disabledDays || [], disabledMonths || [], disabledYears || []);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouched();    
    this.dateChange.emit(this.value);
  }

  public onInput(viewValue: string): void {    
    let date = null;
    if (viewValue.length === 0 || viewValue.length === this.format.length) {
      this.formattedValue = viewValue;      
      this.value = this.dateService.parse(viewValue, this.format);
      date = moment(this.value, this.format).isValid();
    } else {
      date = moment(viewValue, this.format).isValid();
    }    
    if (!date) {
      this.ngControl.control.setErrors({'invalidDate': {value: viewValue}});
    } else {
      this.ngControl.control.setErrors(null);
    }    
  }

  public show(): void {
    if (!this.datedisabled) {
      this.showCalendar = true;
    }
  }

  public hide(): void {
    if (!this.datedisabled) {
      this.showCalendar = false;
    }
  }

  public toggle(): void {
    if (!this.datedisabled) {
      this.showCalendar = !this.showCalendar;
    }
  }

  public selectToday(): void {
    const today = this.dateService.today();
    this.selectionDone(today);
  }

  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();  
  public selectionDone(value: Date): void {
    this.formattedValue = this.dateService.format(value, this.format);
    this.value = value;
    this.dateChange.emit(this.value);
    this.hide();
  }

  public isTodayButtonDisabled(): boolean {
    const today = this.dateService.today();
    return this.dateService.isNotBetween(today, this.minDate, this.maxDate);
  }

  private _handleOutsideClick(): void {
    this.window.addEventListener('click', (event: MouseEvent) => {
      const target: Node = event.target as Node;
      const isInPage: boolean = (target === this.document.body) ? false : this.document.body.contains(target);
      const outsideClick: boolean = !this.divContainer.nativeElement.contains(target);
      if (isInPage && outsideClick) {
        this.hide();
      }
    });
  }

  private _getDisabledDays(changes: SimpleChanges): Date[] {
    const disabledDaysChange: SimpleChange = changes['disabledDays'];
    const disabledDaysChanged: boolean = disabledDaysChange && disabledDaysChange.currentValue;
    return disabledDaysChanged ? disabledDaysChange.currentValue : undefined;
  }

  private _getDisabledMonths(changes: SimpleChanges): Date[] {
    const disabledMonthsChange: SimpleChange = changes['disabledMonths'];
    const disabledMonthsChanged: boolean = disabledMonthsChange && disabledMonthsChange.currentValue;
    return disabledMonthsChanged ? disabledMonthsChange.currentValue : undefined;
  }

  private _getDisabledYears(changes: SimpleChanges): Date[] {
    const disabledYearsChange: SimpleChange = changes['disabledYears'];
    const disabledYearsChanged: boolean = disabledYearsChange && disabledYearsChange.currentValue;
    return disabledYearsChanged ? disabledYearsChange.currentValue : undefined;
  }

  private _setValidators() {
    const validators = [this._dateValidator];
    if (this.ngControl.control.validator) {
      validators.push(this.ngControl.control.validator);
    }
    this.ngControl.control.setValidators(validators);
  }

  private _dateValidator = (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const isInvalidDate = this.formattedValue && !control.value;
    if (isInvalidDate) {
      errors = {'invalidDate': {value: this.formattedValue}};
    }
    return errors;
  }

  private get window(): Window {
    return this.windowService.get();
  }  
}
