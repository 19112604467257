/* pt-br locale */

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';

defineLocale('pt-br', ptBrLocale);

/* pt-br locale */

import { Injectable } from '@angular/core';
import { DatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Injectable()
export class DatePickerConfig extends DatepickerConfig {
  locale = 'pt-br';

  constructor(private localeService: BsLocaleService){
    super();
    localeService.use(this.locale);
  }
}
