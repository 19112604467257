import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'siconv-sub-fieldset',
  template: `  <div class="sub-fieldset" [ngClass]="{'aberto': show, 'fechado': !show}">
    <i *ngIf="collapsible" class="fa" [ngClass]="{'fa-minus': show, 'fa-plus': !show}" (click)="onClick()"></i>
    <div class="title" *ngIf="fieldsetTitle" (click)="onClick()">{{fieldsetTitle}}<em *ngIf="opcional"> (opcional)</em></div>
    <div class="animate-content" *ngIf="show">
      <ng-content></ng-content>
    </div>
  </div>

`,
  styles: [`.sub-fieldset{position:relative;background-color:#fff;padding:0 20px 0 25px;border-left:3px solid #0a6aa6;margin-top:35px;margin-bottom:10px;transition:.3s ease-out}.sub-fieldset .title{font-family:OpenSans-Regular,sans-serif;font-size:14px;color:#0a6aa6;margin-bottom:20px}.sub-fieldset .title:hover{cursor:pointer}.sub-fieldset .menu-bar{font-family:OpenSans-Regular,sans-serif;margin-bottom:0}.sub-fieldset i{position:absolute;color:#0a6aa6;top:4px;left:11px;font-size:13px}.sub-fieldset .animate-content{-webkit-animation:.8s example;animation:.8s example}@-webkit-keyframes example{from{opacity:0}to{opacity:100%}}@keyframes example{from{opacity:0}to{opacity:100%}}.padding{padding-bottom:40px}.aberto{padding-bottom:30px}.fechado{padding-bottom:0}`]
})
export class SubFieldsetComponent implements OnInit {

  @Input() fieldsetTitle = '';
  @Input() collapsible = false;
  @Input() opcional = false;

  show = true;

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    if (this.collapsible) {
      this.show = !this.show;
    }
  }

}
