import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'outputMask'
})

export class OutputMaskPipe implements PipeTransform {
  
  transform(originalValue: string|number, mask: string): string {
    let stringValue: string = '' + originalValue;
    let value = stringValue.replace(/\D/g, '');
    let pad = mask.replace(/\D/g, '').replace(/9/g, '0');
    let maskedValue = pad.substring(0, pad.length - value.length) + value;

    let maskedValuePos = 0;
    value = '';
    for (var i = 0; i < mask.length; i++) {
      if (isNaN(parseInt(mask.charAt(i)))) {
        value += mask.charAt(i);
      } else {
        value += maskedValue[maskedValuePos++];
      }
    }

    if (value.indexOf('_') > -1) {
      value = value.substr(0, value.indexOf('_'));
    }
    
    return value;
  }

}