import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'zeroEsquerdaMask'})
export class ZeroEsquerdaPipe implements PipeTransform {
    transform(value: number, size: number = 2) {
        if (value != null) {
             let valueS = value.toString();
             while (valueS.length < size) {
                valueS = '0' + value;
             }
             if (valueS == '0') {
                valueS = '00';
             }
             return valueS;
         }
         return value;
     }
}
