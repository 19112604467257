import { EventEmitter, Injectable } from '@angular/core';
import { ErrorResponsePayload, HttpErrorTranslator } from "./http-error-translator.service";

const HTTP_UNPROCESSABLE_ENTITY = 422;
const HTTP_CONFLICT = 409;
const HTTP_PRECONDITION_FAILED = 412;
const HTTP_UNAUTHORIZED = 401;

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandler {

  private onError: EventEmitter<ErrorResponsePayload> = new EventEmitter<ErrorResponsePayload>();

  private onBusinessMessages: EventEmitter<ErrorResponsePayload> = new EventEmitter<ErrorResponsePayload>();

  subscribeToError(callback: (system: ErrorResponsePayload) => void) {
    this.onError.subscribe(callback);
  }

  subscribeToBusinessMessages(callback: (system: ErrorResponsePayload) => void) {
    this.onBusinessMessages.subscribe(callback);
  }

  constructor(private httpErrorTranslator: HttpErrorTranslator) {
  }

  handle(response: Response) {     
    if (response.status === HTTP_UNPROCESSABLE_ENTITY) {
      this.onBusinessMessages.emit(this.httpErrorTranslator.translate(response, 'ERROR'));      
    } else if (response.status === HTTP_UNAUTHORIZED) {
      const error = this.httpErrorTranslator.lookupMessage(HTTP_UNAUTHORIZED);
      this.onError.emit(error);
    } else {
      if (response.status !== HTTP_CONFLICT && response.status !== HTTP_PRECONDITION_FAILED) {
        this.onError.emit(this.httpErrorTranslator.translate(response, 'ERROR'));
      }
    }
  }
}
