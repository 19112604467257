import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

import moment from 'moment'; // esse import precisa de 'allowSyntheticDefaultImports = true' no 'tsconfig.lib.json'

@Directive({
  selector: '[siconvMaxDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxDateValidatorDirective, multi: true }]
})
export class MaxDateValidatorDirective implements Validator, OnInit, OnChanges {

  @Input('siconvMaxDate') maxDate: Date;

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = maxDateValidator(this.maxDate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const maxDateChanged = changes['siconvMaxDate'];
    const newMaxDate: Date = (maxDateChanged) ? maxDateChanged.currentValue : this.maxDate;
    this.valFn = maxDateValidator(newMaxDate);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }

}

export function maxDateValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const value = control.value;
    if (value && value instanceof Date && maxDate) {
      const dateMoment = moment(value);
      const maxDateMoment = moment(maxDate);
      if (dateMoment.isAfter(maxDateMoment)) {
        errors = {'invalidMaxDate': {value, 'maxDate': maxDate}};
      }
    }
    return errors;
  };
}
