import { Component, OnDestroy, OnInit, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'lodash';
import { AlertMessage } from '../model/alert-message.model';
import { AlertMessages } from '../model/alert-messages.model';
import { AlertMessageService } from '../services/alert-message.service';
import { HttpErrorHandler } from '../services/http-error-handler.service';

@Component({
  selector: 'siconv-alert-messages',
  template: `<div class="alert" [ngClass]="{'alert-success': message.type === 'SUCCESS', 'alert-info': message.type === 'INFO', 'alert-warning': message.type === 'WARN', 'alert-danger': message.type === 'ERROR'}" role="alert" *ngFor="let message of messages">
  <div class="alert-icone">
    <span *ngIf="message.type === 'SUCCESS'" class="fa fa-check-circle alert-icon"></span>
    <span *ngIf="message.type === 'WARN'" class="fa fa-exclamation-triangle alert-icon"></span>
    <span *ngIf="message.type === 'ERROR'" class="fa fa-exclamation-circle alert-icon"></span>
    <span *ngIf="message.type === 'INFO'" class="fa fa-info-circle alert-icon"></span>
  </div>
  <div class="alert-mensagem">
    <div class="alert-title" [innerHtml]="message.title"></div>
    <div class="description" *ngIf="message.description" [innerHtml]="message.description"></div>
    <ul class="details" style="margin-bottom: 0px;" *ngIf="message.details && message.details.length > 0">
      <li class="detail" *ngFor="let detail of message.details">{{detail}}</li>
    </ul>
    <div class="more" *ngIf="message.options && message.options.hasMore">
      <button type="button" class="btn btn-primary show-more" (click)="showMore(message)">Ver mais</button>
    </div>
  </div>
  <div class="close" *ngIf="message.options && message.options.dismissible">
    <span class="fa fa-times alert-icon-close" (click)="dismiss(message)"></span>
  </div>
</div>
`,
  styles: [`.alert{display:flex;flex-direction:row;align-items:stretch;margin-left:20px;padding-top:10px;padding-bottom:10px}.alert-mensagem{flex:1}.close{cursor:pointer;float:right}.alert-title{font-size:15px;font-weight:700}.show-more{margin:5px 0}.alert-icon{font-size:35px;padding-right:10px}.alert-icon-close{font-size:15px}`],
})

export class AlertMessagesComponent implements OnInit, OnDestroy {

  @Input()
  handler: boolean = true;

  messages: AlertMessages = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private errorHandler: HttpErrorHandler,
    private alertMessageService: AlertMessageService,
  ) {}

  public ngOnInit(): void {

    this.alertMessageService.getMessages$()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((messages: AlertMessages) => {
        this.messages = messages;
        window.scrollTo(0, 0);
      });
    
    if (this.handler) {

      this.errorHandler.subscribeToError(errorResponse => {
        this.alertMessageService.dismissAll();   
        if (errorResponse.messages && errorResponse.messages.length > 0 && errorResponse.messages[0] && errorResponse.messages[0].message != "") {
          this.alertMessageService.error('Server Error', errorResponse.messages[0].message);
        } else if (errorResponse[0] != null && errorResponse[0].error_description[0] != null) {
          this.alertMessageService.error('Server Error', errorResponse[0].error_description[0]);
        } else {
          // Se o response nÃ£o contÃ©m nenhuma mensagem, inclui mensagem padrÃ£o
          this.alertMessageService.error('Server Error',
          'Erro inesperado ao processar a requisiÃ§Ã£o. Tente novamente.');
        }
      });
  
      
      //  Responses com status 422 trazem uma coleÃ§Ã£o de mensagens de diversas severidades.
      this.errorHandler.subscribeToBusinessMessages(errorResponse => {
        this.alertMessageService.dismissAll();
        if (errorResponse.messages && errorResponse.messages.length > 0) {
          let mensagens;
          const erros: any[] = filter(errorResponse.messages, function(o) {
            return o.severity == 'ERROR';
          });       
          if (erros.length > 0) {
            mensagens = [];
            erros.forEach(error => {
              if (this.isNonEmptyString(error.message) && !mensagens.includes(error.message)) {
                mensagens.push(error.message); 
              }            
            });
            if (mensagens.length > 1) {
              this.alertMessageService.error('Erro', '', mensagens); 
            } else {
              this.alertMessageService.error('Erro', mensagens[0]); 
            }
          }       
  
          const warns: any[] = filter(errorResponse.messages, function(o) {
              return o.severity == 'WARN';
          });       
  
          if (warns.length > 0) {
            mensagens = [];
            warns.forEach(warn => {                      
              if (this.isNonEmptyString(warn.message) && !mensagens.includes(warn.message)) {
                mensagens.push(warn.message);
              }             
            });          
            if (mensagens.length > 1) {
              this.alertMessageService.error('AtenÃ§Ã£o', '', mensagens); 
            } else {
              this.alertMessageService.error('AtenÃ§Ã£o', mensagens[0]); 
            }
          }        
        }
      });
    }    
  }

  isNonEmptyString(str: string): boolean {
    return str && str.length > 1;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public dismiss(message: AlertMessage): void {
    this.alertMessageService.dismiss(message);
  }

  public showMore(message: AlertMessage): void {
    this.alertMessageService.showMore(message);
  }
}
