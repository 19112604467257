import { SiconvCardPipe } from './pipes/card-pipe.pipe';

import { SiconCardService } from './services/card.service';
import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe, DecimalPipe, DatePipe, CurrencyPipe } from '@angular/common';
import { SiconvCardComponent } from './components/card.component';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale,  } from 'ngx-bootstrap/locale';

defineLocale('pt-br', ptBrLocale);

export const _PIPE_PROVIDERS = [
  PercentPipe,
  DecimalPipe,
  DatePipe,
  CurrencyPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SiconvCardComponent, SiconvCardPipe],
  providers: [
    SiconCardService, _PIPE_PROVIDERS],
  exports: [SiconvCardComponent],

})
export class SiconvCardModule { }
