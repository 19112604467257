import { Component, Input, OnInit } from '@angular/core';
import { MenuDomain, MenuItem, Menu, Item } from '../../model/menu.model';

@Component({
  selector: 'siconv-menu',
  template: `<div class="topo-borda-menu"></div>

<nav class="navbar navbar-expand-sm">
  <div class="collapse navbar-collapse" id="navbarContent">
    <ul *ngIf="menuDomain.itens" class="navbar-nav mr-auto">
      <!-- Primeiro NÃ­vel -->
      <span *ngFor="let mn of menuDomain.itens">
        <li *ngIf="!mn.itens">
          <a class="nav-link" href="{{mn.action}}">{{mn.label}}</a>
        </li>
        <li *ngIf="mn.itens" class="dropdown">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">{{mn.label}}</a>        
          <!-- Segundo NÃ­vel -->
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <span *ngFor="let menuItem of mn.itens">
              <li *ngIf="!menuItem.itens">
                <a class="nav-link" href="{{menuItem.action}}">{{menuItem.label}}</a>
              </li>
              <li *ngIf="menuItem.itens" class="dropdown-submenu">
                <a class="nav-link dropdown-item" data-toggle="dropdown" href="#" 
                aria-haspopup="true" aria-expanded="false">{{menuItem.label}}</a>        
                <!-- Terceiro NÃ­vel -->
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li *ngFor="let item of menuItem.itens">
                    <a class="nav-link" href={{item.action}}>{{item.label}}</a>                  
                  </li>                               
                </ul>
              </li>
            </span>                             
          </ul>
        </li>
      </span>   
    </ul>    
  </div>
</nav>`,
  styles: [`.topo-borda-menu{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAHCAYAAADJTCeUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gkVEh4ANmydNAAAACtJREFUCNcFwbENACAIAMHPN5gwoDu5GDNYOgYV3tHdY90H7DOCSCSyEjH48CsIhoA3IGwAAAAASUVORK5CYII=);background-repeat:repeat-x;width:100%;height:7px;overflow:hidden}.navbar{height:40px;border-radius:0;min-height:40px;padding-left:40px;margin-bottom:0;background-color:#0a6aa6;width:100%;border:none;color:#fff;font-family:"Open Sans",sans-serif;font-size:15px;position:unset}.navbar .navbar-nav .nav-link{padding-left:0;padding-right:20px;color:#fff}.navbar .navbar-nav a{padding-bottom:10px;padding-top:8px;cursor:pointer;display:block}.navbar .navbar-nav a:hover{background-color:#0a6aa6}.navbar .navbar-nav .open a{color:#fff;background-color:#0a6aa6}.navbar .navbar-nav .open a:focus,.navbar .navbar-nav .open a:hover{background-color:#0a6aa6}.navbar .navbar-nav .dropdown-menu{height:40px;width:200px;background-color:#004a85;border:none;border-radius:0;margin:0}.navbar .navbar-nav .dropdown-menu a{color:#fff;font-family:"Open Sans",sans-serif;font-size:15px;background-color:#004a85;padding:13px 30px;min-height:40px}.navbar .navbar-nav .dropdown-menu a:hover{background-color:#004276;min-height:40px}.dropdown-submenu{position:relative}.dropdown-submenu>.dropdown-menu{top:0;left:100%;margin-top:-6px;margin-left:-1px;border-radius:0 6px 6px}.dropdown-submenu:hover>.dropdown-menu{display:block}.dropdown-submenu>a:after{display:block;content:" ";float:right;width:0;height:0;border-color:transparent transparent transparent #ccc;border-style:solid;border-width:5px 0 5px 5px;margin-top:5px;margin-right:-10px}.dropdown-submenu:hover>a:after{border-left-color:#fff}.dropdown-submenu.pull-left{float:none}.dropdown-submenu.pull-left>.dropdown-menu{left:-100%;margin-left:10px;border-radius:6px 0 6px 6px}`],
})
export class MenuComponent implements OnInit {

  @Input() menu: JSON;
  menuDomain: MenuDomain;

  constructor() { }

  public ngOnInit(): void {
    this.getMenu(this.menu);     
  }

  getMenu(value) {    
    const menuDomain: MenuDomain = new MenuDomain();

    menuDomain.urlImagemLogo = value.UrlImagemLogo;
    menuDomain.urlLinkLogo = value.UrlLinkLogo;
    menuDomain.urlLinkLogout = value.UrlLinkLogout;
    menuDomain.infoUasg = value.InfoUasg;
    menuDomain.infoConvenio = value.InfoConvenio;
    menuDomain.textoLogout = value.TextoLogout;
    menuDomain.identificacaoUsuario = value.IdentificacaoUsuario;
    menuDomain.nomeUsuario = value.NomeUsuario;
    menuDomain.isUsuarioGuest = value.IsUsuarioGuest;

    menuDomain.itens = value.ListaMenu.map(m => {
      const mn: Menu = new Menu();
      mn.id = m.Id;
      mn.itensPorColuna = m.ItensPorColuna;
      mn.label = m.Label;
      mn.labelKey = m.LabelKey;
      mn.funcionalidades = m.Funcionalidades;
      mn.action = m.Action;
      mn.ambiente = m.Ambiente;
      mn.certificated = m.Certificated;
      
      if (m.Items && m.Items.length > 0) {
        mn.itens = m.Items.map(mi => {
          const menuItem: MenuItem = new MenuItem();
          menuItem.label = mi.Label;
          menuItem.labelKey = mi.LabelKey;
          menuItem.funcionalidades = mi.Funcionalidades;
          menuItem.action = mi.Action;
          menuItem.ambiente = mi.Ambiente;
          menuItem.certificated = mi.Certificated;

          if (mi.Subitems && mi.Subitems.length > 0) {
            menuItem.itens = mi.Subitems.map(it => {
              const item: Item = new Item();
              item.label = it.Label;
              item.labelKey = it.LabelKey;
              item.funcionalidades = it.Funcionalidades;
              item.action = it.Action;
              item.ambiente = it.Ambiente;
              item.certificated = it.Certificated;
              return item;
            });
          }            
          return menuItem;
        });
      }           
      return mn;
    });
    this.menuDomain = menuDomain;
  }
}
