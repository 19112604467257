import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { SiconvSortService } from '../../services/sort.service';

@Component({
    selector: '[sortable-column]',
    template: `<ng-content></ng-content>
<i class="fa fa-long-arrow-up" *ngIf="sortDirection === 'asc'" ></i>
<i class="fa fa-long-arrow-down" *ngIf="sortDirection === 'desc'"></i>`
})
export class SortableColumnComponent implements OnInit, OnDestroy {

    constructor(private sortService: SiconvSortService) { }

    @Input('sortable-column')
    columnName: string;

    @Input('sortable-type')
    columnType: string;

    @Input('sort-direction')
    sortDirection: string = '';

    private columnSortedSubscription: Subscription;

    @HostListener('click')
    sort() {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        this.sortService.columnSorted({ sortColumn: this.columnName,  sortType: this.columnType, sortDirection: this.sortDirection });
    }

    ngOnInit() {
        // subscribe to sort changes so we can react when other columns are sorted
        this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
            // reset this column's sort direction to hide the sort icons
            if (this.columnName != event.sortColumn) {
                this.sortDirection = '';
            }
        });
    }

    ngOnDestroy() {
        this.columnSortedSubscription.unsubscribe();
    }
}