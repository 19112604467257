import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertMessagesComponent } from './components/alert-messages.component';
import { HttpErrorTranslator } from './services/http-error-translator.service';
import { HttpErrorHandler } from './services/http-error-handler.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AlertMessagesComponent,    
  ],
  exports: [
    AlertMessagesComponent
  ],
  providers: [
    HttpErrorHandler,
    HttpErrorTranslator
  ]
})
export class SiconvAlertMessagesModule {}
