import { Directive } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

const BLACK_LIST: string[] = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
];

@Directive({
  selector: '[siconvValidCnpj]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CnpjValidatorDirective, multi: true }]
})
export class CnpjValidatorDirective implements Validator {

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = cnpjValidator();
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }

}

export function cnpjValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    const validCnpj = isValidCnpj(value);
    return validCnpj ? null : { 'invalidCnpj': { value } };
  };
}

function isValidCnpj(maskedCnpj: string): boolean {
  if (!maskedCnpj) {
    return true;
  }
  let unmaskedCnpj: string = maskedCnpj.replace(/[^\d]+/g, '');
  if (!unmaskedCnpj) {
    return true;
  }
  if (unmaskedCnpj.length !== 14 || isBlackListed(unmaskedCnpj)) {
    return false;
  }
  let dvs: string = unmaskedCnpj.substring(12);
  let dv1: number = calculateDv(unmaskedCnpj.substring(0, 12));
  let dv2: number = calculateDv(unmaskedCnpj.substring(0, 13));
  return (dvs.charAt(0) === dv1.toString() && dvs.charAt(1) === dv2.toString());
}

function isBlackListed(cnpj: string): boolean {
  return BLACK_LIST.indexOf(cnpj) >= 0;
}

function calculateDv(base: string): number {
  let length: number = base.length;
  let sum: number = 0;
  let pos: number = length - 7;
  for (let i: number = length; i >= 1; i--) {
    sum += Number.parseInt(base.charAt(length - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let result: number = sum % 11 < 2 ? 0 : 11 - sum % 11;
  return result;
}
