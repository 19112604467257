import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[siconvMaxFileSize]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxFileSizeValidatorDirective, multi: true }]
})
export class MaxFileSizeValidatorDirective implements Validator, OnInit, OnChanges {

  @Input('siconvMaxFileSize') maxSize: number;

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = fileSizeValidator(this.maxSize);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const maxSizeChanged = changes['siconvMaxFileSize'];
    const newMaxSize: number = (maxSizeChanged) ? maxSizeChanged.currentValue : this.maxSize;
    this.valFn = fileSizeValidator(newMaxSize);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }

}

export function fileSizeValidator(maxSize: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const value = control.value;
    if (value && value instanceof File && maxSize) {
      const size = value.size;
      if (size > maxSize) {
        errors = {'invalidFileSize': {value, 'maxSize': maxSize}};
      }
    }
    return errors;
  };
}
