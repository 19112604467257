import { FormControl, NgModel } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

export const DEFAULT_ERRORS = {
  required: 'Campo obrigatÃ³rio',
  invalid: 'Formato invÃ¡lido',
  email: 'Email invÃ¡lido',
  cnpj: 'CNPJ invÃ¡lido',
  cpf: 'CPF invÃ¡lido',
  date: 'Data invÃ¡lida',
  minDate: 'Data deve ser maior ou igual a {0}',
  maxDate: 'Data deve ser menor ou igual a {0}',
  fileSize: 'Tamanho do arquivo deve ser menor ou igual a {0}',
  decendio: 'DecÃªncio invÃ¡lido',
};

@Component({
  selector: 'siconv-local-messages',
  template: `<div class="description error local-message" *ngIf="control.invalid && (control.dirty || control.touched)">
  <ng-content></ng-content>
  <div *ngIf="control.errors.required">
    {{requiredMessage}}
  </div>
  <div *ngIf="control.errors.pattern">
    {{patternMessage}}
  </div>
  <div *ngIf="control.errors.minlength">
    {{minlengthMessage}}
  </div>
  <div *ngIf="control.errors.maxlength">
    {{maxlengthMessage}}
  </div>
  <div *ngIf="control.errors.email">
    {{emailMessage}}
  </div>
  <div *ngIf="control.errors.invalidCnpj">
    {{cnpjMessage}}
  </div>
  <div *ngIf="control.errors.invalidCpf">
    {{cpfMessage}}
  </div>
  <div *ngIf="control.errors.invalidDate">
    {{dateMessage}}
  </div>
  <div *ngIf="control.errors.invalidDay">
    {{dayMessage}}
  </div>
  <div *ngIf="control.errors.invalidMonth">
    {{monthMessage}}
  </div>
  <div *ngIf="control.errors.invalidYear">
    {{yearMessage}}
  </div>
  <div *ngIf="control.errors.invalidMinDate">
    {{minDateMessage|stringFormat:(control.errors.invalidMinDate.minDate|date:'dd/MM/yyyy')}}
  </div>
  <div *ngIf="control.errors.invalidMaxDate">
    {{maxDateMessage|stringFormat:(control.errors.invalidMaxDate.maxDate|date:'dd/MM/yyyy')}}
  </div>
  <div *ngIf="control.errors.invalidFileSize">
    {{fileSizeMessage|stringFormat:(control.errors.invalidFileSize.maxSize|fileSize)}}
  </div>
  <div *ngIf="control.errors.invalidDecendio">
    {{decendioMessage}}
  </div>
</div>
`,
  styles: [`.local-message{margin-top:0;color:#d32f2f}`],
})

export class LocalMessagesComponent implements OnInit {

  @Input() requiredMessage: string = DEFAULT_ERRORS.required;
  @Input() patternMessage: string = DEFAULT_ERRORS.invalid;
  @Input() minlengthMessage: string = DEFAULT_ERRORS.invalid;
  @Input() maxlengthMessage: string = DEFAULT_ERRORS.invalid;
  @Input() emailMessage: string = DEFAULT_ERRORS.email;
  @Input() cnpjMessage: string = DEFAULT_ERRORS.cnpj;
  @Input() cpfMessage: string = DEFAULT_ERRORS.cpf;
  @Input() dateMessage: string = DEFAULT_ERRORS.date;
  @Input() dayMessage: string = DEFAULT_ERRORS.date;
  @Input() monthMessage: string = DEFAULT_ERRORS.date;
  @Input() yearMessage: string = DEFAULT_ERRORS.date;
  @Input() minDateMessage: string = DEFAULT_ERRORS.minDate;
  @Input() maxDateMessage: string = DEFAULT_ERRORS.maxDate;
  @Input() fileSizeMessage: string = DEFAULT_ERRORS.fileSize;
  @Input() decendioMessage: string = DEFAULT_ERRORS.decendio;

  @Input() control: NgModel|FormControl;

  constructor() { }

  public ngOnInit(): void {}

}
