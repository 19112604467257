import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { SiconvDirectivesModule } from '../../directives/public_api';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SiconvDirectivesModule
  ],
  declarations: [
    InputComponent
  ],
  exports: [
    InputComponent
  ]
})
export class SiconvInputModule {}
