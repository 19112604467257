import { Component, OnInit, ContentChildren, QueryList } from '@angular/core';
import { VerticalTabComponent } from '../vertical-tab.component';

@Component({
  selector: 'siconv-vertical-tab-set',
  template: `<div class="tab-container container-tab-vertical">
  <div class="tab-content">
    <div class="tab-vert-e">
      <ul class="nav nav-tabs tabs-left tab-vertical-esquerda" role="tablist" *ngIf="tabs && tabs.length > 0">
        <li role="presentation" *ngFor="let tab of tabs" [class.active]="tab.active">
          <a href="javascript:void(0)" [class.disabled]="tab.disabled" (click)="select(tab)" role="tab" data-toggle="tab">{{tab.name}}</a>
        </li>
      </ul>
    </div>
    <div class="tab-vert-d">
      <div class="tab-content tab-vertical-direita">
        <ng-content></ng-content>
        <div style="clear: left;"></div>
      </div>
    </div>
  </div>
</div>


`,
  styles: [`.tab-pane:not(.active){display:none}.disabled{cursor:not-allowed}.nav-tabs>li>a{border-radius:0}.container-tab-vertical>.nav-tabs{margin-left:0}.container-tab-vertical .tab-vert-e{width:20%;padding:20px 0;float:left;font-size:15px;letter-spacing:tracking(25px)}.container-tab-vertical .tab-vert-d{width:80%;float:left;min-height:300px;background:#fff;border-left:2px solid #d1d4d1;border-top:2px solid #d1d4d1;border-right:1px solid #d1d4d1;border-bottom:1px solid #d1d4d1;padding-left:40px;border-radius:5px;margin-bottom:50px}.container-tab-vertical .tab-vertical-direita{width:100%;background:#fff!important}.container-tab-vertical .tab-vertical-esquerda{margin-left:0;width:100%}.container-tab-vertical .tab-vertical-esquerda>li{width:100%;background:#fff}.container-tab-vertical .tab-vertical-esquerda>li>a{border-top:1px solid #cfcfcf;background:#ededed;border-bottom:1px solid #ccc;color:#5c5c5c;margin-right:0;padding-left:40px;padding-top:17px;padding-bottom:10px}.container-tab-vertical .tab-vertical-esquerda>li>a:hover{border-top:1px solid #cfcfcf;background:#fff;border-bottom:1px solid #e5e5e5;margin-right:0}.container-tab-vertical .tab-vertical-esquerda>li.active>a,.container-tab-vertical .tab-vertical-esquerda>li.active>a:hover{background:#0a6aa6;color:#fff}.container-tab-vertical .tab-content{padding:40px 20px 40px 0}.container-tab-vertical .tabs-left>li>a{display:block}.container-tab-vertical .tabs-right>li>a{margin-right:0}.container-tab-vertical .sideways{margin-top:50px;border:none;position:relative}.container-tab-vertical .sideways>li{height:20px;width:120px;margin-bottom:100px}.container-tab-vertical .nav-tabs>li.active>a,.container-tab-vertical .nav-tabs>li.active>a:focus,.container-tab-vertical .nav-tabs>li.active>a:hover{border-right:none}`]
})

export class VerticalTabSetComponent implements OnInit {

  @ContentChildren(VerticalTabComponent) tabs: QueryList<VerticalTabComponent>;

  constructor() { }
  
  ngOnInit() { }

  public select(tab: VerticalTabComponent): void {
    if (!tab.disabled) {
      this.tabs.forEach(t => {
        if (t === tab) {
          t.select();
        } else {
          t.unselect();
        }
      });
    }
  }

  public selectIndex(index: number): void {
    const tab: VerticalTabComponent = this.tabs.find((tab: VerticalTabComponent, i: number) => index === i);
    this.select(tab);
  }

}