import { NgModule } from '@angular/core';
import { FieldsetComponent } from './components/fieldset.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FieldsetComponent,
  ],
  exports: [
    FieldsetComponent
  ]
})
export class SiconvFieldsetModule {}
