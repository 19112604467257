import { Component, OnInit, AfterViewInit, Input, ViewChild, forwardRef, Injector, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'siconv-select',
  template: `<ng-container *ngIf="inline">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</ng-container>

<ng-container *ngIf="!inline">
  <div class="form-group">
    <label [for]="name" *ngIf="label" class="control-label" [class.obrigatorio]="required">{{label}}<span *ngIf="!required && !disabled"><em> (opcional)</em></span></label>
    <ng-container *ngTemplateOutlet="template"></ng-container>
    <div class="description">{{description}}</div>
  </div>
</ng-container>

<ng-template #template>
    <ng-select 
      #selectElement 
      #selectCtrl="ngModel" 
      [name]="name" 
      [ngClass]="customClass" 
      [siconvHasError]="ngControl.control" 
      [ngModel]="value" 
      (ngModelChange)="onSelect($event)" 
      [required]="required" 
      [disabled]="disabled" 
      [clearable] = "clearable" 
      clearAllText="Limpar" required>      
      <ng-option *ngFor="let value of values; let i = index" [value]="value">{{labels[i] || value}}</ng-option>
    </ng-select>
</ng-template>
`,
  styles: [`.ng-select{border:1px solid #ccc;border-radius:4px}.form-group .form-control-icon{position:absolute;z-index:2;display:block;right:0;width:2.375rem;height:2.37rem;line-height:2.375rem;text-align:center;pointer-events:none;top:-2px}.disabled{background:#e9ecef}`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => SelectComponent)
  }]
})

export class SelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  // ControlValueAccessor methods ...

  private _value: any;

  get value(): any {
    return this._value;
  }

  @Input('value')
  set value(value: any) {    
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  customClass: string = '';
  setDisabledState( isDisabled : boolean ) : void {
    this.disabled = isDisabled;
    const action = isDisabled ? 'addClass' : 'removeClass';
    if (action == 'addClass') {
      this.customClass = 'disabled';
    } else {
      this.customClass = '';
    }  
  }

  // ... ControlValueAccessor methods

  private compareFn = (value1: any, value2: any): boolean => {
    return value1 === value2;
  }

  @Input() compareWith: any = this.compareFn;
  @Input() inline: boolean = false;
  @Input() label: string;
  @Input() description: string = '';
  @Input() labels: string[] = [];
  @Input() name: string = 'select';
  @Input() required: boolean = false;
  @Input() clearable: boolean = true;  
  @Input() selectClass: string = 'form-control';
  @Input() unselectedValue = null;
  @Input() values: any[] = [];
  @Input() disabled: boolean = false;

  ngControl: NgControl;

  @ViewChild('selectElement') selectElement;

  constructor(
    private injector: Injector,
  ) { }

  public ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  public ngAfterViewInit(): void { }

  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  public onSelect(value: any): void {
    this.value = value;
    this.selectChange.emit(this.value);
  }

}
