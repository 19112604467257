import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalMessagesComponent } from './components/local-messages.component';
import { SiconvDirectivesModule } from '../../directives/public_api';
import { SiconvPipesModule } from '../../pipes/public_api';

@NgModule({
  imports: [
    CommonModule,
    SiconvDirectivesModule,
    SiconvPipesModule

  ],
  declarations: [
    LocalMessagesComponent    
  ],
  exports: [
    LocalMessagesComponent    
  ],
})
export class SiconvLocalMessageModule {}
