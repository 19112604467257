import { Injectable } from '@angular/core';
import { DisabledDates } from '../model/disabled-dates.model';
import moment from 'moment'; // esse import precisa de 'allowSyntheticDefaultImports = true' no 'tsconfig.lib.json'

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() {}

  public format(date: Date, format: string): string {
    return (!date) ? undefined : moment(date).format(format);
  }

  public parse(date: string, format: string): Date {
    const momentValue = date ? moment(date, format, true) : undefined;
    return momentValue && momentValue.isValid() ? momentValue.toDate() : undefined;
  }

  public buildDisabledDates(disabledDays: Date[], disabledMonths: Date[], disabledYears: Date[]): DisabledDates {
    const disabledDates: DisabledDates = [];
    disabledDays.forEach(day => disabledDates.push({date: day, mode: 'day'}));
    disabledMonths.forEach(month => disabledDates.push({date: month, mode: 'month'}));
    disabledYears.forEach(year => disabledDates.push({date: year, mode: 'year'}));
    return disabledDates;
  }

  public today(): Date {
    return moment().startOf('day').toDate();
  }

  public isNotBetween(date: Date, minDate: Date, maxDate: Date) {
    const todayMoment = moment(date);
    const minDateMoment = minDate ? moment(minDate) : undefined;
    const maxDateMoment = maxDate ? moment(maxDate) : undefined;

    if (minDateMoment && maxDateMoment) {
      return !todayMoment.isBetween(minDateMoment, maxDateMoment);
    } else if (minDateMoment) {
      return todayMoment.isBefore(minDateMoment);
    } else if (maxDateMoment) {
      return todayMoment.isAfter(maxDateMoment);
    } else {
      return false;
    }

  }
}
