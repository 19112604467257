import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

import moment from 'moment'; // esse import precisa de 'allowSyntheticDefaultImports = true' no 'tsconfig.lib.json'

@Directive({
  selector: '[siconvMinDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDateValidatorDirective, multi: true }]
})
export class MinDateValidatorDirective implements Validator, OnInit, OnChanges {

  @Input('siconvMinDate') minDate: Date;

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = minDateValidator(this.minDate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const minDateChanged = changes['siconvMinDate'];
    const newMinDate: Date = (minDateChanged) ? minDateChanged.currentValue : this.minDate;

    this.valFn = minDateValidator(newMinDate);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }

}

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const value = control.value;
    if (value && value instanceof Date && minDate) {
      const dateMoment = moment(value);
      const minDateMoment = moment(minDate);
      if (dateMoment.isBefore(minDateMoment)) {
        errors = {'invalidMinDate': {value, 'minDate': minDate}};
      }
    }
    return errors;
  };
}
