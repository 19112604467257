import { NgModule } from '@angular/core';
import { PopoverModule, TooltipModule, ModalModule, BsLocaleService } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpleTimer } from 'ng2-simple-timer';

@NgModule({
  imports: [
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule
  ],
  declarations: [],
  exports: [
    PopoverModule,
    TooltipModule,
    ModalModule,
    NgSelectModule
  ],
  providers: [
    SimpleTimer,
    BsLocaleService
  ]
})
export class SiconvCommonModule {}
