import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './components/tab.component';
import { TabSetComponent } from './components/tab-set/tab-set.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TabComponent, TabSetComponent],
  exports: [TabComponent, TabSetComponent],

})
export class SiconvTabModule { }
