import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table.component';
import { TableExportComponent } from './components/table-export/table-export.component';
import { PaginationModule } from 'ngx-bootstrap';
import { SiconvTXTService } from './services/txt.service';
import { SiconvCSVService } from './services/csv.service';
import { SiconvXLSService } from './services/xls.service';
import { SiconvPDFService } from './services/pdf.service';
import { SiconvXMLService } from './services/xml.service';
import { SiconvSortService } from './services/sort.service';
import { SortableColumnComponent } from './components/sortable-column/sortable-column.component';
import { TableSortDirective } from './directive/table-sort.directive';
import { FormsModule } from '@angular/forms';
import { ToggleComponent } from './components/toggle/toggle.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule.forRoot()
  ],
  declarations: [TableComponent, TableExportComponent, SortableColumnComponent, ToggleComponent, TableSortDirective],
  exports: [TableComponent, TableExportComponent, SortableColumnComponent, ToggleComponent, TableSortDirective],
  providers: [ 
    SiconvTXTService,
    SiconvCSVService,
    SiconvXLSService,
    SiconvPDFService,
    SiconvXMLService,
    SiconvSortService
    ]

})
export class SiconvTableModule { }
