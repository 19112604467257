import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'siconv-tab',
  template: `<div role="tabpanel" class="tab-pane" [class.active]="active">
  <ng-content></ng-content>
</div>`,
  styles: [`.tab-pane:not(.active){display:none}.disabled{cursor:not-allowed}.tab-container .nav.nav-tabs{background-color:#fff}.tab-content{background:#ececec;padding:30px 40px 10px;min-height:450px}.tab-content siconv-tab tab,.tab-content tab{padding-bottom:10px;padding-left:20px}.nav{padding-left:40px}.nav-tabs .tab:first-child{margin-left:40px}.nav-tabs .nav-link:hover{background-color:#ececec}.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link,.nav-tabs .nav-link.active{border:1px solid #ccc}.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active{font-size:14px;color:#fff;background-color:#0a6aa6}.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link{font-size:14px;color:#454546;background-color:#fff}.nav-tabs>li.active>a,.nav-tabs>li.active>a:focus,.nav-tabs>li.active>a:hover{background:#0a6aa6;color:#fff}.nav-tabs .nav-link{border-top-left-radius:5px;border-top-right-radius:5px}.nav.nav-tabs.segundo-nivel{border-bottom:1px solid #0a6aa6;background:#ececec}.nav-tabs.segundo-nivel>li.active>a,.nav-tabs.segundo-nivel>li.active>a:focus{border:none;border-bottom:4px solid #0a6aa6!important;color:#0a6aa6;background:#ececec}.nav-tabs.segundo-nivel>li.active>a:hover{background:#fff}.nav-link.segundo-nivel{border:none!important;font-size:14px;border-bottom:1px solid #0a6aa6;color:#454546;background:#ececec}.nav-link.segundo-nivel:hover{background:#fff}`]
})

export class TabComponent implements OnInit {

  @Input() name: string;
  @Input() active: boolean;
  @Input() disabled: boolean;

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  public select(): void {
    if (!this.disabled && !this.active) {
      this.active = true;
      this.activeChange.emit(this.active);
    }
  }

  public unselect(): void {
    if (!this.disabled && this.active) {
      this.active = false;
      this.activeChange.emit(this.active);
    }
  }

}