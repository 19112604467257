export class Item {
  label: string;
  labelKey: string;
  funcionalidades: string;
  action: string;
  ambiente: string;
  certificated: boolean;
}

export class MenuItem {
  itens: Item[];
  label: string;
  labelKey: string;
  funcionalidades: string;
  action: string;
  ambiente: string;
  certificated: boolean;
}

export class Menu {
  itens: MenuItem[];
  id: string;
  itensPorColuna: number;
  label: string;
  labelKey: string;
  funcionalidades: string;
  action: string;
  ambiente: string;
  certificated: boolean;
}

export class MenuDomain {
  itens: Menu[];
  urlImagemLogo: string;
  urlLinkLogo: string;
  urlLinkLogout: string;
  infoUasg: string;
  infoConvenio: string;
  textoLogout: string;
  identificacaoUsuario: string;
  nomeUsuario: string;
  isUsuarioGuest: boolean;
}

