import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { TopComponent } from './components/top/top.component';

import { UserProfilesPipe } from './pipes/user-profiles.pipe';
import { UserRolesPipe } from './pipes/user-roles.pipe';
import { MenuComponent } from './components/menu/menu.component';
import { SiconvPipesModule } from '../../pipes/public_api';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
  imports: [
    CommonModule,    
    RouterModule.forChild([]),
    SiconvPipesModule
  ],
  declarations: [
    BreadcrumbsComponent,
    HeaderComponent,
    TopComponent,
    MenuComponent,
    UserProfilesPipe,
    UserRolesPipe,
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    TopComponent,
    MenuComponent,
    UserProfilesPipe,
    UserRolesPipe
  ]
})
export class SiconvHeaderModule {}
