import { Pipe, PipeTransform  } from '@angular/core';

import { User } from '../model/user.model';
import { Role } from '../model/role.model';

@Pipe({
  name: 'userRoles'
})
export class UserRolesPipe implements PipeTransform {
  
  transform (user: User): string {
    return (!user || !user.roles) ? undefined : user.roles.map((role: Role) => role.name).join(', ');
  }

}