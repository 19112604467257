import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputMaskDirective } from './list/input-mask.directive';
import { NumbersOnlyDirective } from './list/numbers-only.directive';
import { ReactiveInputMaskDirective } from './list/reactive-input-mask.directive';
import { CpfValidatorDirective } from './list/cpf-validator.directive';
import { CnpjValidatorDirective } from './list/cnpj-validator.directive';
import { HasErrorDirective } from './list/has-error.directive';
import { MaxFileSizeValidatorDirective } from './list/max-file-size-validator.directive';
import { DisabledDaysValidatorDirective } from './list/disabled-days-validator.directive';
import { DisabledMonthsValidatorDirective } from './list/disabled-months-validator.directive';
import { DisabledYearsValidatorDirective } from './list/disabled-years-validator.directive';
import { MaxDateValidatorDirective } from './list/max-date-validator.directive';
import { MinDateValidatorDirective } from './list/min-date-validator.directive';
import { CurrencyMaskDirective } from './list/currency.directive';
import { UpperCaseDirective } from './list/uppercase.directive';
import { LowerCaseDirective } from './list/lowercase.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CnpjValidatorDirective,
    CpfValidatorDirective,
    CurrencyMaskDirective,
    DisabledDaysValidatorDirective,
    DisabledMonthsValidatorDirective,
    DisabledYearsValidatorDirective,
    HasErrorDirective,
    InputMaskDirective,
    MaxDateValidatorDirective,
    MaxFileSizeValidatorDirective,
    MinDateValidatorDirective,
    NumbersOnlyDirective,
    UpperCaseDirective,
    LowerCaseDirective,
    ReactiveInputMaskDirective
  ],
  exports: [
    CnpjValidatorDirective,
    CpfValidatorDirective,
    CurrencyMaskDirective,
    DisabledDaysValidatorDirective,
    DisabledMonthsValidatorDirective,
    DisabledYearsValidatorDirective,
    HasErrorDirective,
    InputMaskDirective,
    MaxDateValidatorDirective,
    MaxFileSizeValidatorDirective,
    MinDateValidatorDirective,
    NumbersOnlyDirective,
    UpperCaseDirective,
    LowerCaseDirective,
    ReactiveInputMaskDirective
  ],
})
export class SiconvDirectivesModule {}
