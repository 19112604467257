import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileSizePipe } from './list/file-size.pipe';
import { JoinPipe } from './list/join.pipe';
import { OutputMaskPipe } from './list/output-mask.pipe';
import { StringFormatPipe } from './list/string-format.pipe';
import { StripHtmlPipe } from './list/strip-html.pipe';
import { ZeroEsquerdaPipe } from './list/zero-esquerda.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FileSizePipe,
    JoinPipe,
    OutputMaskPipe,
    StringFormatPipe,
    StripHtmlPipe,
    ZeroEsquerdaPipe,
  ],
  exports: [
    FileSizePipe,
    JoinPipe,
    OutputMaskPipe,
    StringFormatPipe,
    StripHtmlPipe,
    ZeroEsquerdaPipe
  ],
})
export class SiconvPipesModule {}
