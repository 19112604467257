import { Component, OnInit, forwardRef, Input, Injector, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { InputMaskService } from '../../../../directives/services/input-mask.service';
import { SimpleMaskMoneyService } from '../../../../directives/services/simple-mask-money.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
};

@Component({
  selector: 'siconv-input',
  template: `<ng-container>
  <div class="form-group">
    <label *ngIf="label" class="control-label" [class.obrigatorio]="required">{{label}}<span *ngIf="!required && !disabled"><em> (opcional)</em></span></label>
    <ng-container *ngTemplateOutlet="template"></ng-container>
    <div class="description">{{description}}</div>
  </div>
</ng-container>

<ng-template #template>
  <div class="input-group">

    <span
      class="fa fa-exclamation-circle form-control-icon"
      *ngIf="ngControl.control.invalid && (ngControl.control.dirty || ngControl.control.touched)">
    </span>

    <span
      class="fa fa-check form-control-icon"
      *ngIf="ngControl.control.valid && (ngControl.control.dirty || ngControl.control.touched)">
    </span>

    <input *ngIf="!mask && !currency"
      type="text"
      #inputCtr="ngModel"
      [siconvHasError]="ngControl.control"
      class="form-control"
      [maxlength]="maxlength"
      [disabled]="disabled"
      (blur)="onBlur()"
      [required]="required"
      [(ngModel)]="value">

    <input *ngIf="!mask && currency"
      type="text"
      #inputCtr="ngModel"
      [siconvHasError]="ngControl.control"
      class="form-control"
      [maxlength]="maxlength"
      [disabled]="disabled"
      (blur)="onBlur()"
      [siconvCurrency]="currency"
      [required]="required"
      [(ngModel)]="value">

    <input *ngIf="mask"
      type="text"
      #inputCtr="ngModel"
      [siconvHasError]="ngControl.control"
      class="form-control"
      [maxlength]="maxlength"
      [disabled]="disabled"
      (blur)="onBlur()"
      [required]="required"
      [(ngModel)]="value"
      siconvInputMask="{{mask}}">

  </div>
</ng-template>
`,
  styles: [`.disabled{background:#d3d3d3}`],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputComponent implements OnInit, ControlValueAccessor {

  @Input() label: string = '';
  @Input() description: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() mask: string = null;
  @Input() currency: number = null;
  @Input() maxlength: string;

  @Output() unmask: EventEmitter<string> = new EventEmitter();

  @ViewChild('inputCtr') inputCtr: NgModel;

  modelValue: string = ''
  invalidControl: boolean = false;
  ngControl: NgControl;

  constructor(
    private injector: Injector,
    private inputMaskService: InputMaskService,
    private currencyMaskDirective: SimpleMaskMoneyService
  ) { }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
  }

  // Placeholders for the callbacks which are later providesd by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get value(): any {
    return this.modelValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
      if (v !== this.modelValue) {
          this.modelValue = v;
          this.onChangeCallback(v);
          this.onTouchedCallback();
      }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
    this.getUnmask();
  }

  getUnmask() {
    if (this.value) {
      const v = this.value.replace(/\D+/g, '');
      this.unmask.emit(v);
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    this.modelValue = value;
    if (this.inputCtr && value === null) {
      this.inputCtr.reset();
      return;
    }
    if (value !== this.modelValue) {
      if (this.modelValue != null && this.mask != null) {
        if ((!this.modelValue.toString().includes('.') && this.mask.includes('.'))
         || (!this.modelValue.toString().includes('-') && this.mask.includes('-'))
         || (!this.modelValue.toString().includes('/') && this.mask.includes('/'))){
          const maskedValue = this.inputMaskService.maskInput(this.modelValue, this.mask);
          this.modelValue = maskedValue;
        }
      }

      if (this.modelValue != null && this.currency != null) {
        const currencyValue = this.currencyMaskDirective.format(this.modelValue, this.currency);
        this.modelValue = currencyValue;
      }
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
