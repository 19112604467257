import { Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/filter';
import { ActivatedRoute, Router, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';

export interface IBreadcrumb {
  label: string;
  route: string;
  queryParams?: Params;
  fragment?: string;
}

@Component({
  selector: 'siconv-breadcrumbs',
  template: `<ol class="breadcrumb hidden-xs">
  <a [routerLink]="home"><span class="fa fa-home home"></span></a>
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <a *ngIf="breadcrumb.route && !last" [routerLink]="breadcrumb.route" [queryParams]="breadcrumb.queryParams"
      [fragment]="breadcrumb.fragment">&nbsp;/&nbsp;{{ breadcrumb.label }}</a>
    <span *ngIf="!breadcrumb.route || last">&nbsp;/&nbsp;{{ breadcrumb.label }}</span>
  </li>
</ol>
`,
  styles: [`.breadcrumb{background:#ececec;font-size:13px;font-family:OpenSans-Regular,sans-serif;height:30px;width:100%;padding-left:40px;padding-top:0;padding-bottom:0;color:#0a6aa6;letter-spacing:.075px;display:flex;align-content:center;align-items:center}.breadcrumb .home{padding-right:5px;color:#0a6aa6;font-size:18px}.breadcrumb a,.breadcrumb span{color:#0a6aa6}ol{margin-bottom:0}`]
})
export class BreadcrumbsComponent implements OnDestroy {

  private subscription: Subscription;

  @Input() home = '';
  breadcrumbs: IBreadcrumb[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.subscription = this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(() => {
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
      });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {

    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_LABEL = 'label';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {

      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // verify the custom data property "breadcrumb" is specified on the route
      if (
        !child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB) ||
        child.snapshot.data[ROUTE_DATA_BREADCRUMB] === null
      ) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        route: url,
        fragment: child.snapshot.fragment,
        queryParams: child.snapshot.queryParams
      };

      if (!child.snapshot.data[ROUTE_DATA_BREADCRUMB]) {
        breadcrumb = {
          label: child.snapshot.data[ROUTE_DATA_LABEL],
          route: null,
          fragment: child.snapshot.fragment,
          queryParams: child.snapshot.queryParams
        };
      }

      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
