import { Directive, ElementRef, Input, Renderer2, DoCheck } from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';

@Directive({
  selector: '[siconvHasError]'
})
export class HasErrorDirective implements DoCheck {

  @Input('siconvHasError') control: NgModel|FormControl;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngDoCheck(): void {
    const hasError = this.control ? this.control.invalid && (this.control.dirty || this.control.touched) : false;
    if (hasError) {
      this.renderer.addClass(this.el.nativeElement, 'ng-invalid');
      this.renderer.removeClass(this.el.nativeElement, 'ng-valid');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'ng-valid');
      this.renderer.removeClass(this.el.nativeElement, 'ng-invalid');
    }
  }

}
