import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'siconv-fieldset',
  template: `  <div class="fieldset" [ngClass]="{'padding': !collapsed}">
    <i *ngIf="collapsible" class="fa" [ngClass]="{'fa-chevron-up': collapsed, 'fa-chevron-down': !collapsed}" (click)="onClick()"></i>
    <div *ngIf="collapsed">
      <ng-content select=".align-right"></ng-content>
    </div>
    <div class="title" *ngIf="fieldsetTitle" (click)="onClick()">{{fieldsetTitle}}<em *ngIf="opcional"> (opcional)</em></div>
    <div class="animate-content" *ngIf="collapsed">
      <ng-content></ng-content>
    </div>
  </div>

`,
  styles: [`.fieldset{position:relative;margin:0 0 20px 20px;background-color:#fff;padding:20px 30px 30px 40px;box-shadow:-1px -1px 2px #d3d3d3;transition:.3s ease-out}.fieldset .title{font-family:OpenSans-Regular,sans-serif;font-size:18px;color:#0a6aa6;margin-bottom:20px}.fieldset .title:hover{cursor:pointer}.fieldset .menu-bar{font-family:OpenSans-Regular,sans-serif;margin-bottom:0}.fieldset i{position:absolute;color:#0a6aa6;top:25px;left:20px}.fieldset .animate-content{-webkit-animation:.8s example;animation:.8s example}@-webkit-keyframes example{from{opacity:0}to{opacity:100%}}@keyframes example{from{opacity:0}to{opacity:100%}}.padding{padding-bottom:1px}`]
})
export class FieldsetComponent implements OnInit {

  @Input() fieldsetTitle = '';
  @Input() opcional = false;
  @Input() collapsible = false;
  @Input() collapsed = true;

  //show = true;

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
    }
  }

}
