import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

import moment from 'moment'; // esse import precisa de 'allowSyntheticDefaultImports = true' no 'tsconfig.lib.json'

@Directive({
  selector: '[siconvDisabledDays]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DisabledDaysValidatorDirective, multi: true }]
})
export class DisabledDaysValidatorDirective implements Validator, OnInit, OnChanges {

  @Input('siconvDisabledDays') disabledDays: Date[];

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = disabledDaysValidator(this.disabledDays);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabledDaysChanged = changes['siconvDisabledDays'];
    const newDisabledDays: Date[] = (disabledDaysChanged) ? disabledDaysChanged.currentValue : this.disabledDays;
    this.valFn = disabledDaysValidator(newDisabledDays);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }

}

export function disabledDaysValidator(disabledDays: Date[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const value = control.value;
    if (value && value instanceof Date && disabledDays) {
      const validDay = isValidDay(value, disabledDays);
      if (!validDay) {
        errors = {'invalidDay': {value}};
      }
    }
    return errors;
  };
}

function isValidDay(date: Date, disabledDays: Date[]): boolean {
  const dateMoment = moment(date);
  return disabledDays.every(day => !dateMoment.isSame(day, 'day'));
}
