import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

import moment from 'moment'; // esse import precisa de 'allowSyntheticDefaultImports = true' no 'tsconfig.lib.json'

@Directive({
  selector: '[siconvDisabledMonths]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DisabledMonthsValidatorDirective, multi: true }]
})
export class DisabledMonthsValidatorDirective implements Validator, OnInit, OnChanges {

  @Input('siconvDisabledMonths') disabledMonths: Date[];

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = disabledMonthsValidator(this.disabledMonths);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabledMonthsChanged = changes['siconvDisabledMonths'];
    const newDisabledMonths: Date[] = (disabledMonthsChanged) ? disabledMonthsChanged.currentValue : this.disabledMonths;
    this.valFn = disabledMonthsValidator(newDisabledMonths);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }

}

export function disabledMonthsValidator(disabledMonths: Date[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const value = control.value;
    if (value && value instanceof Date && disabledMonths) {
      const validMonth = isValidMonth(value, disabledMonths);
      if (!validMonth) {
        errors = {'invalidMonth': {value}};
      }
    }
    return errors;
  };
}

function isValidMonth(date: Date, disabledMonths: Date[]): boolean {
  const dateMoment = moment(date);
  return disabledMonths.every(month => !dateMoment.isSame(month, 'month'));
}
