import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PickListModel } from '../model/picklist.model';

@Component({
  selector: 'siconv-picklist',
  template: `<div class="form-inline" id="picklistAssociar">  
  <div class="pick-lado">
    <label *ngIf="sourceLabel" class="control-label">{{sourceLabel}}</label>
    <div class="form-control  div-overflow" style="width: 100%;">
      <select class="pick-selection" [(ngModel)]="selectedSources" multiple>
        <option *ngFor="let option of sources" [value]="option" (dblclick)="add()" [disabled]="disabled">{{option.name}}</option>
      </select>    
    </div>
  </div>
  <div class="pick-meio text-center">
    <div class="btn-group-vertical">
      <button type="button" (click)="add()" class="btn btn-secondary pick-button" [disabled]="disabled">&gt;</button>
      <button type="button" (click)="addAll()" class="btn btn-secondary pick-button" [disabled]="disabled">&gt;&gt;</button>
      <button type="button" (click)="remove()" class="btn btn-secondary pick-button" [disabled]="disabled">&lt;</button>
      <button type="button" (click)="removeAll()" class="btn btn-secondary pick-button" [disabled]="disabled">&lt;&lt; </button>
    </div>
  </div>
  <div class="pick-lado">
    <label *ngIf="targetLabel" class="control-label">{{targetLabel}}</label>
    <div class="form-control div-overflow" style="width: 100%;">     
      <select class="pick-selection" [(ngModel)]="selectedTargets"  multiple>
        <option *ngFor="let option of targets" [value]="option" (dblclick)="remove()" [disabled]="disabled">{{option.name}}</option>
      </select>
    </div>
  </div>
</div>
  
  `,
  styles: [`.pick-button{height:30px;width:30px;background-color:#0a6aa6;color:#fff;font-weight:bolder;margin-top:10px;margin-bottom:10px;margin-left:0;padding:0;border-radius:4px!important;font-size:16px}.pick-button-disabled{height:30px;width:30px;background-color:#737373;color:#fff;font-weight:bolder;margin-top:10px;margin-bottom:10px;margin-left:0;padding:0;border-radius:4px!important}.div-overflow{overflow:auto;height:100%;min-height:180px;box-shadow:2px 2px 4px rgba(0,0,0,.5);padding:0}.pick-selection{border:0;overflow:hidden;padding:5px;outline:0}.pick-selection option{font-family:"Open Sans";font-size:14px;color:#222}.pick-lado{width:45%;color:#454546;font-family:"Open Sans";font-size:13px}.pick-lado .control-label{justify-content:left}.pick-meio{width:10%;min-width:30px}`]
})
export class PickListComponent implements OnInit {

  @Input() sourceLabel: string = '';
  @Input() sources: PickListModel[] = [];
  @Input() targetLabel: string = '';
  @Input() targets: PickListModel[] = [];
  @Input() disabled: boolean = false;

  @Output() changed = new EventEmitter<any>();

  selectedSources: PickListModel[] = [];
  selectedTargets: PickListModel[] = [];

  constructor() { }

  ngOnInit() {
  }

  changedEvent() {
    this.changed.emit(this.targets.slice());
  }

  includes(colection: any[], item: any){
    let ret: boolean = false;
    colection.forEach(element => {
      if (element == item) {
        ret = true;
      }
    });    
    return ret;
  }

  add() {
    this.sources = this.sources.filter((source) => !this.includes(this.selectedSources, source));
    this.targets = this.targets.concat(this.selectedSources);
    this.selectedSources = [];
    this.changedEvent();
  }

  addAll() {
    this.selectedSources = this.sources;
    this.add();
  }

  remove() {
    this.targets = this.targets.filter((target) => !this.includes(this.selectedTargets, target));
    this.sources = this.sources.concat(this.selectedTargets);
    this.selectedTargets = [];
    this.changedEvent();
  }

  removeAll() {
    this.selectedTargets = this.targets;
    this.remove();
  }
}
