import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: User;

  private userSource: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);
  private user$: Observable<User> = this.userSource.asObservable();

  constructor() { }

  setUser(user: User): void {
    this.user = user;
    this.userSource.next(user);
  }

  getUser(): Observable<User> {
    return this.user$;
  }

}
