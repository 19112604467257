import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Injector } from '@angular/core';
import { InputMaskService } from '../services/input-mask.service';

@Directive({ selector: '[siconvInputMask]' })
export class InputMaskDirective implements OnInit {

  @Input('siconvInputMask') mask: string;
  @Input('siconvInputMaskInvalidAllowed') invalidAllowed: boolean;

  private _maxlength: number;
  private _invalidAllowed: boolean;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private inputMaskService: InputMaskService,
  ) {}

  ngOnInit() {
    this.setMaxLength();
    this.setInvalidAllowed();
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {

    // retorna caso pressionado backspace
    if ($event.key === 'Backspace') {
      return;
    }

    const maskedValue = this.inputMaskService.maskInput($event.target.value, this.mask);
    this.setValue(maskedValue);

  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if (!this._invalidAllowed) {
      const isWrongLength = ($event.target.value.length !== this.mask.length);
      if (isWrongLength) {
        $event.stopPropagation();
        this.setValue('');
      }
    }
  }

  private setValue(value: string) {
    this.el.nativeElement.value = value;
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }

  private setMaxLength(): void {
    this._maxlength = this.mask.length;
    this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this._maxlength.toString());
  }

  private setInvalidAllowed(): void {
    const localConfig: boolean = this.invalidAllowed;
    this._invalidAllowed = (localConfig !== undefined) ? localConfig : true;
  }

}
