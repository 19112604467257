import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap';
import { SearchCriteria } from '../model/search-criteria';
import { DataExport } from '../model/export';

@Component({
  selector: 'siconv-table',
  template: `<div class="overflow">
    <table table-sort (sorted)="onSorted($event)" class="table" *ngIf="listaPaginada">
        <ng-content></ng-content>    
        <!-- <tr *ngIf="!data || data.length == 0"><td colspan="100%">Nenhum Item incluÃ­do</td></tr> -->
    </table>        
    <div class="table-footer" *ngIf="data">
        <siconv-table-export *ngIf="export && export.data && export.data.length != 0" style="float: left;" [fileExportName]="fileExportName" [export]="export"></siconv-table-export>
        <div *ngIf="(!data || data.length == 0)" style="float: left; margin-top: 15px; font-size: 12px; ">Nenhum item incluÃ­do</div>        
        <div class="row" style="float: right;" *ngIf="data && pagination">            
            <p style="margin-top: 15px; margin-right: 5px; font-size: 12px; width: 80px;">Itens por pÃ¡g.</p>
            <select class="form-control select-pagination" #s (change)="selectMaxPage(s.value)">
                <option class="select-pagination" value="5">5</option>
                <option class="select-pagination" value="10" selected>10</option>
                <option class="select-pagination" value="15">15</option>
                <option class="select-pagination" value="20">20</option>
            </select>
            <pagination #paginationCtr [boundaryLinks]="true" [maxSize]="maxSize"
                [itemsPerPage]="maxPag" [rotate]="true" [totalItems]="data.length" (pageChanged)="pageChanged($event)"
                class="paginacao" [(ngModel)]="currentPage" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
            </pagination>
        </div>    
    </div>
</div>
`,
  styles: [`.table-footer{margin-top:0;background-color:#fff;margin-bottom:30px}.paginacao{margin-top:15px;margin-right:15px}.select-pagination{margin-top:5px;margin-right:10px;font-size:12px;width:65px;border:1px solid transparent;border-color:transparent transparent rgba(0,0,0,.1);box-shadow:inset 0 0 0 rgba(0,0,0,.075)}@media only screen and (max-width:600px){.overflow{overflow-x:auto}}`]
})

export class TableComponent implements OnInit, OnChanges {

  @Input() data: any[];
  @Input() pagination: boolean;
  @Input() fileExportName: string = '';
  @Input() export: DataExport;
  @Input() currentPage: number;

  @Output() listaPaginada: EventEmitter<any[]> = new EventEmitter();
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('paginationCtr') paginationCtr;

  maxPag = 10;
  maxSize = 10;
  startPag: number;
  endPag: number;

  constructor() {
  }

  ngOnInit() {
    this.startPag = 0;
    this.endPag = 10;
    this.getListaPaginada();
  }

  ngOnChanges() {
    this.getListaPaginada();
  }

  getListaPaginada(): void {
    //Passagem por VALOR e nÃ£o por referÃªncia
    if (this.data) {
      let lista = this.data.slice();
      this.listaPaginada.emit(lista.slice(this.startPag, this.endPag));
    }
  }

  pageChanged(event: PageChangedEvent): void {
    this.startPag = (event.page - 1) * event.itemsPerPage;
    this.endPag = event.page * event.itemsPerPage;
    this.getListaPaginada();
    this.currentPageChange.emit(event.page);
  }

  onSorted($event) {
    this.getData($event);
  }

  getData(criteria: SearchCriteria): void {
    this.data = this.data.slice().sort((a, b) => {
      if (criteria.sortDirection === 'desc') {
        switch (criteria.sortType) {
          case 'number':
            return a[criteria.sortColumn] - b[criteria.sortColumn];
          case 'date':
            return this.sortDate(a[criteria.sortColumn], b[criteria.sortColumn]);
          case 'object':
            const aa = JSON.stringify(a[criteria.sortColumn]);
            const bb = JSON.stringify(b[criteria.sortColumn]);

            return this.sortString(aa, bb);
          default:
            return this.sortString(a[criteria.sortColumn], b[criteria.sortColumn]);
        }
      } else {
        switch (criteria.sortType) {
          case 'number':
            return b[criteria.sortColumn] - a[criteria.sortColumn];
          case 'date':
            return this.sortDate(b[criteria.sortColumn], a[criteria.sortColumn]);
          case 'object':
            const aa = JSON.stringify(a[criteria.sortColumn]);
            const bb = JSON.stringify(b[criteria.sortColumn]);

            return this.sortString(bb, aa);
          default:
            return this.sortString(b[criteria.sortColumn], a[criteria.sortColumn]);
        }
      }
    });

    this.getListaPaginada();
  }

  sortString(a, b): number {
    a = (a != null ? a.toLowerCase() : '');
    b = (b != null ? b.toLowerCase() : '');
    return a > b ? -1 : b > a ? 1 : 0;
  }

  sortDate(a, b): number {
    a = (a != null ? new Date(a) : 0);
    b = (b != null ? new Date(b) : 0);
    return a - b;
  }

  selectMaxPage(value): void {
    this.maxPag = value;
    this.startPag = 0;
    this.endPag = this.maxPag;
    this.getListaPaginada();
  }
}