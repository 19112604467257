import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'siconv-vertical-tab',
  template: `<div role="tabpanel" class="tab-pane" [class.active]="active">
  <ng-content></ng-content>
</div>`,
  styles: [`.tab-pane:not(.active){display:none}.disabled{cursor:not-allowed}.nav-tabs>li>a{border-radius:0}.container-tab-vertical>.nav-tabs{margin-left:0}.container-tab-vertical .tab-vert-e{width:20%;padding:20px 0;float:left;font-size:15px;letter-spacing:tracking(25px)}.container-tab-vertical .tab-vert-d{width:80%;float:left;min-height:300px;background:#fff;border-left:2px solid #d1d4d1;border-top:2px solid #d1d4d1;border-right:1px solid #d1d4d1;border-bottom:1px solid #d1d4d1;padding-left:40px;border-radius:5px;margin-bottom:50px}.container-tab-vertical .tab-vertical-direita{width:100%;background:#fff!important}.container-tab-vertical .tab-vertical-esquerda{margin-left:0;width:100%}.container-tab-vertical .tab-vertical-esquerda>li{width:100%;background:#fff}.container-tab-vertical .tab-vertical-esquerda>li>a{border-top:1px solid #cfcfcf;background:#ededed;border-bottom:1px solid #ccc;color:#5c5c5c;margin-right:0;padding-left:40px;padding-top:17px;padding-bottom:10px}.container-tab-vertical .tab-vertical-esquerda>li>a:hover{border-top:1px solid #cfcfcf;background:#fff;border-bottom:1px solid #e5e5e5;margin-right:0}.container-tab-vertical .tab-vertical-esquerda>li.active>a,.container-tab-vertical .tab-vertical-esquerda>li.active>a:hover{background:#0a6aa6;color:#fff}.container-tab-vertical .tab-content{padding:40px 20px 40px 0}.container-tab-vertical .tabs-left>li>a{display:block}.container-tab-vertical .tabs-right>li>a{margin-right:0}.container-tab-vertical .sideways{margin-top:50px;border:none;position:relative}.container-tab-vertical .sideways>li{height:20px;width:120px;margin-bottom:100px}.container-tab-vertical .nav-tabs>li.active>a,.container-tab-vertical .nav-tabs>li.active>a:focus,.container-tab-vertical .nav-tabs>li.active>a:hover{border-right:none}`]
})

export class VerticalTabComponent implements OnInit {

  @Input() name: string;
  @Input() active: boolean;
  @Input() disabled: boolean;

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  public select(): void {
    if (!this.disabled && !this.active) {
      this.active = true;
      this.activeChange.emit(this.active);
    }
  }

  public unselect(): void {
    if (!this.disabled && this.active) {
      this.active = false;
      this.activeChange.emit(this.active);
    }
  }

}