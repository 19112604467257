import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject, Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { AlertMessage } from '../model/alert-message.model';
import { AlertMessages } from '../model/alert-messages.model';
import { AlertMessageOptions } from '../model/alert-message-options.model';
import { AlertMessageType } from '../model/alert-message-type.model';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  private messages: AlertMessages = [];

  private messagesSource = new BehaviorSubject<AlertMessages>(this.messages);
  private messages$ = this.messagesSource.asObservable();

  private showMoreSource = new Subject<AlertMessage>();
  private showMore$: Observable<AlertMessage> = this.showMoreSource.asObservable();

  constructor() { }

  public getMessages$(): Observable<AlertMessages> {
    return this.messages$;
  }

  public success(title: string, description?: string, details?: string[], options?: AlertMessageOptions): AlertMessage {
    return this.push('SUCCESS', title, description, details, options);
  }

  public info(title: string, description?: string, details?: string[], options?: AlertMessageOptions): AlertMessage {
    return this.push('INFO', title, description, details, options);
  }

  public warn(title: string, description?: string, details?: string[], options?: AlertMessageOptions): AlertMessage {
    return this.push('WARN', title, description, details, options);
  }

  public error(title: string, description?: string, details?: string[], options?: AlertMessageOptions): AlertMessage {
    return this.push('ERROR', title, description, details, options);
  }

  public dismiss(message: AlertMessage): void {
    this.messages = this.messages.filter((alertMessage) => {
      return alertMessage !== message;
    });
    this.messagesSource.next(this.messages);
  }

  public dismissAll(): void {
    this.messages.splice(0, this.messages.length);
    this.messagesSource.next(this.messages);
  }

  public showMore(message: AlertMessage): void {
    this.showMoreSource.next(message);
  }

  public onShowMore(): Observable<AlertMessage> {
    return this.showMore$;
  }

  private push(type: AlertMessageType, title: string, description?: string,
                details?: string[], options?: AlertMessageOptions): AlertMessage {

    let message = this.messages.find(msg =>
          msg.type === type
          && msg.title === title
          && msg.description === description
        );

    if (!message) {
      message = {type, title, description, details, count: 1,
        options: options || { dismissible: true, delay: 0 }
      };
      this.messages.push(message);
    } else {
      message.count++;
      if (details) {
        if (!message.details) { message.details = []; }
        message.details.push(... details);
      }
    }

    this.messagesSource.next(this.messages);

    if (this.isAutoDismissible(message)) {
      timer(message.options.delay)
        .pipe(take(1))
        .subscribe(() => this.dismiss(message));
    }

    return message;
  }

  private isAutoDismissible(message: AlertMessage): boolean {
    return message.options && message.options.delay > 0;
  }

}
