import { Component, OnInit, ContentChildren, QueryList } from '@angular/core';
import { SecondTabComponent } from '../second-tab.component';

@Component({
  selector: 'siconv-second-tab-set',
  template: `<ul class="nav nav-tabs segundo-nivel" role="tablist" *ngIf="tabs && tabs.length > 0">
  <li role="presentation" *ngFor="let tab of tabs" [class.active]="tab.active">
    <a href="javascript:void(0)" class="nav-link segundo-nivel" [class.disabled]="tab.disabled" (click)="select(tab)" role="tab" data-toggle="tab">{{tab.name}}</a>
  </li>
</ul>
<div class="tab-content" style="padding-left: 20px;">
  <ng-content></ng-content>
</div>`,
  styles: [`.tab-pane:not(.active){display:none}.disabled{cursor:not-allowed}.nav.nav-tabs.segundo-nivel{border-bottom:1px solid #0a6aa6;background:#f5f5f7}.nav-tabs.segundo-nivel>li.active>a,.nav-tabs.segundo-nivel>li.active>a:focus{border:none;border-bottom:4px solid #0a6aa6!important;color:#0a6aa6;background:#f5f5f7}.nav-tabs.segundo-nivel>li.active>a:hover{background:#fff}.nav-link.segundo-nivel{border:none!important;font-size:14px;border-bottom:1px solid #0a6aa6;color:#454546;background:#f5f5f7}.nav-link.segundo-nivel:hover{background:#fff}`]
})

export class SecondTabSetComponent implements OnInit {

  @ContentChildren(SecondTabComponent) tabs: QueryList<SecondTabComponent>;

  constructor() { }
  
  ngOnInit() { }

  public select(tab: SecondTabComponent): void {
    if (!tab.disabled) {
      this.tabs.forEach(t => {
        if (t === tab) {
          t.select();
        } else {
          t.unselect();
        }
      });
    }
  }

  public selectIndex(index: number): void {
    const tab: SecondTabComponent = this.tabs.find((tab: SecondTabComponent, i: number) => index === i);
    this.select(tab);
  }

}