import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalTabComponent } from './components/vertical-tab.component';
import { VerticalTabSetComponent } from './components/vertical-tab-set/vertical-tab-set.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [VerticalTabComponent, VerticalTabSetComponent],
  exports: [VerticalTabComponent, VerticalTabSetComponent],

})
export class SiconvVerticalTabModule { }
