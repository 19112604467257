import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'siconv-second-tab',
  template: `<div role="tabpanel" class="tab-pane" [class.active]="active">
  <ng-content></ng-content>
</div>`,
  styles: [`.tab-pane:not(.active){display:none}.disabled{cursor:not-allowed}.nav.nav-tabs.segundo-nivel{border-bottom:1px solid #0a6aa6;background:#f5f5f7}.nav-tabs.segundo-nivel>li.active>a,.nav-tabs.segundo-nivel>li.active>a:focus{border:none;border-bottom:4px solid #0a6aa6!important;color:#0a6aa6;background:#f5f5f7}.nav-tabs.segundo-nivel>li.active>a:hover{background:#fff}.nav-link.segundo-nivel{border:none!important;font-size:14px;border-bottom:1px solid #0a6aa6;color:#454546;background:#f5f5f7}.nav-link.segundo-nivel:hover{background:#fff}`]
})

export class SecondTabComponent implements OnInit {

  @Input() name: string;
  @Input() active: boolean;
  @Input() disabled: boolean;

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  public select(): void {
    if (!this.disabled && !this.active) {
      this.active = true;
      this.activeChange.emit(this.active);
    }
  }

  public unselect(): void {
    if (!this.disabled && this.active) {
      this.active = false;
      this.activeChange.emit(this.active);
    }
  }

}