import { Directive } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

const BLACK_LIST: string[] = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999'
];

@Directive({
  selector: '[siconvValidCpf]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CpfValidatorDirective, multi: true }]
})
export class CpfValidatorDirective implements Validator {

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = cpfValidator();
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }

}

export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    const validCpf = isValidCpf(value);
    return validCpf ? null : { 'invalidCpf': { value } };
  };
}

function isValidCpf(maskedCpf: string): boolean {
  if (!maskedCpf) {
    return true;
  }
  let unmaskedCpf: string = maskedCpf.replace(/[^\d]+/g, '');
  if (!unmaskedCpf) {
    return true;
  }
  if (unmaskedCpf.length !== 11 || isBlackListed(unmaskedCpf)) {
    return false;
  }
  let dvs: string = unmaskedCpf.substring(9);
  let dv1: number = calculateDv(unmaskedCpf.substring(0, 9));
  let dv2: number = calculateDv(unmaskedCpf.substring(0, 10));
  return (dvs.charAt(0) === dv1.toString() && dvs.charAt(1) === dv2.toString());
}

function isBlackListed(unmaskedCpf: string): boolean {
  return BLACK_LIST.indexOf(unmaskedCpf) >= 0;
}

function calculateDv(base: string): number {
  let length: number = base.length;
  let sum: number = 0;  
  for (let i: number = 0; i < length; i++) {
    sum += Number.parseInt(base.charAt(i)) * ((length + 1) - i);
  }
  let result: number = 11 - (sum % 11);  
  if (result === 10 || result == 11) {
    result = 0;
  }
  return result;
}
