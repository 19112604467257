import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})

export class StripHtmlPipe implements PipeTransform {

  transform(htmlString: string): string {
    return htmlString ? htmlString.replace(/<.*?>/g, ''): undefined;
  }

}