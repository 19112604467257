import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export interface ManagedStatusCode {
  code: number;
  message: string;
}

export interface ApplicationMessage {
  message: string;
  severity: 'ERROR' | 'WARN' | 'INFO' | 'SUCESS';
}

export interface ErrorResponsePayload {
  messages: ApplicationMessage[];
  uid: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpErrorTranslator {
  managedStatusCodes: ManagedStatusCode[] = [
    {
      code: 0,
      message: 'Erro inesperado ao processar a requisiÃ§Ã£o. Tente novamente. ' +
        'Caso o erro persista, contacte o administrador do sistema'
    },
    {
      code: 400,
      message: 'RequisiÃ§Ã£o invÃ¡lida! Verifique o dado enviado.'
    },
    {
      code: 401,
      message: 'RequisiÃ§Ã£o nÃ£o autorizada. Para acessar o dado Ã© necessÃ¡rio estar autenticado' +
        ' no sistema. Verifique se sua sessÃ£o expirou.'
    },
    {
      code: 403,
      message: 'RequisiÃ§Ã£o nÃ£o autorizada. Ã provÃ¡vel que vocÃª nÃ£o possua acesso ao dado solicitado.'
    },
    {
      code: 404,
      message: 'O recurso solicitado nÃ£o foi encontrado!. Verifique se a url estÃ¡ correta.'
    },
    {
      code: 408,
      message: 'Tempo expirado. NÃ£o foi obter uma resposta para a solicitaÃ§Ã£o enviada.'
    },
    {
      code: 500,
      message: 'Erro inesperado ao processar a requisiÃ§Ã£o. Tente novamente. ' +
        'Caso o erro persista, contacte o administrador do sistema'
    }
  ];

  translate(response: any, severity: any): ErrorResponsePayload {
    let erro: string = '';   
    let error: ErrorResponsePayload;

    if (response && response.error) {  
      if (response.status == 0) {
        error = { uid: 'unknown', messages: [] };
      } else {
        erro = response.error;
      }      
    } else {
      let errorMessage: ManagedStatusCode = this.lookupMessage(response.status);
      if (!errorMessage) {
        // this.logUnknownError(response);      
        errorMessage = { code: response.status, message: 'NÃ£o Ã© possÃ­vel prosseguir neste momento. Tente novamente mais tarde.' };
        erro = errorMessage.message;
      }
    }

    const listaErros = erro.length > 1 ? erro.split('-') : [];
    if (listaErros.length > 1) {
      const listaMsg = [];
      listaErros.forEach(e => {
        listaMsg.push({ 'message': e, 'severity': severity });
      });
      error = { uid: 'unknown', messages: listaMsg };
    } else {
      const appMessage: ApplicationMessage = { 'message': erro, 'severity': severity };
      error = { uid: 'unknown', messages: [appMessage] };
    }
    window.scrollTo(0, 0);
    return error;
  }

  public lookupMessage(statusCode: number) {
    return _.find(this.managedStatusCodes, (obj) => obj.code === statusCode);
  }
}