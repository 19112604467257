import { Component, OnInit, Input } from '@angular/core';
import { SiconvXLSService } from '../../services/xls.service';
import { SiconvPDFService } from '../../services/pdf.service';
import { SiconvXMLService } from '../../services/xml.service';
import { SiconvCSVService } from '../../services/csv.service';
import { SiconvTXTService } from '../../services/txt.service';
import { DataExport } from '../../model/export';

@Component({
  selector: 'siconv-table-export',
  template: `<p>Exportar o conteÃºdo dessa tabela no formato 
    <a class="link" (click)="exportAsTXT()">TXT</a>,
    <a class="link" (click)="exportAsCSV()">CSV</a>,
    <a class="link" (click)="exportAsXLSX()">XLS</a>,
    <a class="link" (click)="exportAsPDF()">PDF</a> ou
    <a class="link" (click)="exportAsXML()">XML</a>
</p>`,
  styles: [`*{font-family:OpenSans-Regular,sans-serif;margin-top:15px}p{font-size:12px}p .link{color:#337ab7}p a{cursor:pointer}p a:hover{color:red}`]
})
export class TableExportComponent implements OnInit {
    
    @Input() export: DataExport;
    @Input() fileExportName: string = '';
    
    fileName: string = "file";

    constructor(
        private txtService: SiconvTXTService,
        private csvService: SiconvCSVService,
        private excelService: SiconvXLSService,
        private pdfService: SiconvPDFService,
        private xmlService: SiconvXMLService) { 
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.fileName = this.fileExportName;
    }

    exportAsTXT(): void {
        this.txtService.exportAsTXTFile(this.export.columns, this.export.data, this.fileName);
    }

    exportAsCSV(): void {       
        this.csvService.exportAsCSVFile(this.export.columns, this.export.data, this.fileName);
    }

    exportAsPDF(): void {                   
        this.pdfService.exportAsPDFFile(this.export.columns, this.export.data, this.fileName);
    }

    exportAsXML(): void {
        const data = new Array();
        this.export.data.forEach(value => {
            var obj = {};
            obj['row'] = {};
            this.export.columns.forEach((key, index) => {
                obj['row'][key.replace(" ", "-")] = value[index];
            });
            data.push(obj);
        });
        this.xmlService.exportAsXMLFile(data, this.fileName);
    }

    exportAsXLSX(): void {                  
        let values = []
        values.push(this.export.columns)
        this.export.data.forEach(element => {
            values.push(element)
        });        
        this.excelService.exportAsExcelFile(values, this.fileName);
    }
}
