import { Component, OnInit, Output, EventEmitter, Input, forwardRef, ViewChild, Injector, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_FILE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileComponent),
    multi: true
};

@Component({
  selector: 'siconv-file',
  template: `<ng-container>
  <div class="form-group">
    <label *ngIf="label" class="control-label">{{label}}</label>
    <ng-container *ngTemplateOutlet="template"></ng-container>      
    <div class="description">{{description}}</div>
  </div>
</ng-container>
  
<ng-template #template>
  <div class="input-group input-file">
    <input [id]='id' 
      type='file'
      #inputCtr 
      [siconvHasError]="ngControl.control" 
      class="form-control"  
      (blur)="onBlur()"
      placeholder="Escolha o arquivo" 
      accept=".PDF,.DOC,.DOCX,.XLS,.XLSX,.JPG,.JPEG,.PNG,.ODT,.ODS"
      [(ngModel)]="value">
    <label [for]='id' class="file-icon"><i class="fa fa-arrow-circle-up"></i></label>
    <input *ngIf="inputCtr.files[0]" id="anexoFile" type="text" maxlength="50" class="form-control file-text" [value]="inputCtr.files[0].name" disabled>   
    <input *ngIf="!inputCtr.files[0]" id="anexoFile" type="text" maxlength="50" class="form-control file-text" [value]="inputCtr.value" disabled>
  </div>
</ng-template>

`,
  styles: [`@charset "UTF-8";.input-file input[type=file]{display:none}.input-file .file-text{border-radius:0 5px 5px 0!important}.input-file .file-icon{margin:0!important;border-radius:5px 0 0 5px!important}.input-file label{background-color:#0a6aa6;color:#fff;cursor:pointer;margin:10px;padding:6px 20px}`],
  providers: [CUSTOM_FILE_CONTROL_VALUE_ACCESSOR]
})
export class FileComponent implements OnInit, ControlValueAccessor {

  @Input() label: string = '';
  @Input() description: string = '';
  @Output() changeFile = new EventEmitter();

  @ViewChild('inputCtr') inputCtr;

  id: string = 'selecao-arquivo' + Math.random();

  modelValue: string = ''
  invalidControl: boolean = false;

  ngControl: NgControl;

  constructor(
    private injector: Injector,
    private renderer : Renderer2
  ) { }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);    
  }

  //Placeholders for the callbacks which are later providesd by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {        
    return this.modelValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
      if (v !== this.modelValue) {
          this.modelValue = v;
          this.onChangeCallback(v);
      }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();    
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.modelValue) 
    {
      this.modelValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public onSelect(value: any): void {
    this.value = value;
    this.changeFile.emit(this.value);
  }
}
