import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, Validators, ValidatorFn, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

import moment from 'moment'; // esse import precisa de 'allowSyntheticDefaultImports = true' no 'tsconfig.lib.json'

@Directive({
  selector: '[siconvDisabledYears]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DisabledYearsValidatorDirective, multi: true }]
})
export class DisabledYearsValidatorDirective implements Validator, OnInit, OnChanges {

  @Input('siconvDisabledYears') disabledYears: Date[];

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = disabledYearsValidator(this.disabledYears);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabledYearsChanged = changes['siconvDisabledYears'];
    const newDisabledYears: Date[] = (disabledYearsChanged) ? disabledYearsChanged.currentValue : this.disabledYears;
    this.valFn = disabledYearsValidator(newDisabledYears);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }

}

export function disabledYearsValidator(disabledYears: Date[]): ValidatorFn {

  return (control: AbstractControl): ValidationErrors => {
    let errors = null;
    const value = control.value;
    if (value && value instanceof Date && disabledYears) {
      const validMonth = isValidYear(value, disabledYears);
      if (!validMonth) {
        errors = {'invalidYear': {value}};
      }
    }
    return errors;
  };
}

function isValidYear(date: Date, disabledYears: Date[]): boolean {
  const dateMoment = moment(date);
  return !disabledYears ? true : disabledYears.every(year => !dateMoment.isSame(year, 'year'));
}
