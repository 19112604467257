import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

const TXT_EXTENSION = '.txt';

@Injectable()
export class SiconvTXTService {

  constructor() { }

  public exportAsTXTFile(columns: any[], rows: any[], fileName: string): void {    
    let text: string = columns.toString() + this.breakLine();
    rows.forEach(element => {
      text = text + element.toString() + this.breakLine();
    });
    this.saveAsTXTFile(text, fileName);
  }

  private breakLine(): string {
    return '\r\n';
  }

  private saveAsTXTFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: 'text/plain'});

    if (fileName != null && fileName != undefined && fileName != "") {
      fileName = fileName + '_' + new Date().getTime();
    } else {
      fileName = "" + new Date().getTime();
    } 

    FileSaver.saveAs(data, fileName + TXT_EXTENSION);
  }
}