import { Injectable } from '@angular/core';

declare var jsPDF: any;
const PDF_EXTENSION = '.pdf';

@Injectable()
export class SiconvPDFService {

  constructor() { }

  public exportAsPDFFile(columns: any[], rows: any[], fileName: string): void {    
    var doc = new jsPDF('l', 'pt');
    
    doc.autoTable(columns, rows, {
      startY: 20,
      margin: { horizontal: 5 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' },
      headerStyles: {
          fillColor: [51, 122, 183],
          textColor: [255],
          halign: 'center'
      },
      theme: 'grid'
   });

    if (fileName != null && fileName != undefined && fileName != "") {
      fileName = fileName + '_' + new Date().getTime();
    } else {
      fileName = "" + new Date().getTime();
    } 

    doc.save(fileName + PDF_EXTENSION);
  }
}