import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondTabComponent } from './components/second-tab.component';
import { SecondTabSetComponent } from './components/second-tab-set/second-tab-set.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SecondTabComponent, SecondTabSetComponent],
  exports: [SecondTabComponent, SecondTabSetComponent],

})
export class SiconvSecondTabModule { }
