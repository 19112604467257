import { Pipe, PipeTransform } from '@angular/core';

const regex = new RegExp("{-?[0-9]+}", "g");

@Pipe({
  name: 'stringFormat'
})

export class StringFormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    return value.replace(regex, (item) => {
      var intVal = parseInt(item.substring(1, item.length - 1));
      var replace;
      if (intVal >= 0) {
        replace = args[intVal];
      } else if (intVal === -1) {
        replace = "{";
      } else if (intVal === -2) {
        replace = "}";
      } else {
        replace = "";
      }
      return replace;
    }); 
  }

}