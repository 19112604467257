import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DatepickerModule, DatepickerConfig } from 'ngx-bootstrap/datepicker';

import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DatePickerConfig } from './services/date-picker-config.service';

import { SiconvPipesModule } from '../../pipes/public_api';
import { SiconvDirectivesModule } from '../../directives/public_api';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale,  } from 'ngx-bootstrap/locale';

defineLocale('pt-br', ptBrLocale);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatepickerModule,
    SiconvDirectivesModule,
    SiconvPipesModule
  ],
  declarations: [
    DatePickerComponent,
  ],
  exports: [
    DatePickerComponent,
  ],
  providers: [
    {
      provide: DatepickerConfig,
      useClass: DatePickerConfig
    }
  ]
})
export class SiconvDatePickerModule {}
