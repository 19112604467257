import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[siconvReactiveInputMask]' })
export class ReactiveInputMaskDirective implements OnInit {

  @Input('siconvReactiveInputMask') mask: string;
  @Input('siconvReactiveInputMaskInvalidAllowed') invalidAllowed: boolean;

  private _maxlength: number;
  private _invalidAllowed: boolean;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private ngControl : NgControl
  ) {}

  ngOnInit() {
    this.setMaxLength();
    this.setInvalidAllowed();
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {
    var value = $event.target.value.replace(/\D/g, '');
    var pad = this.mask.replace(/\D/g, '').replace(/9/g, '_');
    var maskedValue = value + pad.substring(0, pad.length - value.length);

    // retorna caso pressionado backspace
    if ($event.keyCode === 8) {
      return;
    }

    var maskedValuePos = 0;
    value = '';
    for (var i = 0; i < this.mask.length; i++) {
      if (isNaN(parseInt(this.mask.charAt(i)))) {
        value += this.mask.charAt(i);
      } else {
        value += maskedValue[maskedValuePos++];
      }
    }

    if (value.indexOf('_') > -1) {
      value = value.substr(0, value.indexOf('_'));
    }

    this.ngControl.control.setValue(value);
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if (!this._invalidAllowed) {
      const isWrongLength = ($event.target.value.length !== this.mask.length);
      if (isWrongLength) {
        $event.target.value = '';
        this.ngControl.control.setValue('');
      }
    }
  }

  private setMaxLength(): void {
    this._maxlength = this.mask.length;
    this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this._maxlength.toString());
  }

  private setInvalidAllowed(): void {
    const localConfig: boolean = this.invalidAllowed;
    // const globalConfig: boolean = this.configService.isInputMaskInvalidAllowed();
    const globalConfig: boolean = true;
    this._invalidAllowed = (localConfig !== undefined) ? localConfig : globalConfig;
  }

}
