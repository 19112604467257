import { Injectable } from '@angular/core';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Injectable()
export class SiconvCSVService {

  file: Angular5Csv = null;

  constructor() { }

  public exportAsCSVFile(columns: any[], json: any[], fileName: string): void {
    if (fileName != null && fileName != undefined && fileName != "") {
      fileName = fileName + '_' + new Date().getTime();
    } else {
      fileName = "" + new Date().getTime();
    }    
    this.file = new Angular5Csv(json, fileName, this.getOptions(columns));
  }

  private getOptions(columns: any[]): any {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: columns
    };
    return options;
  }
}