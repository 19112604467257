import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'siconv-title',
  template: `<div class="convenio-bar">
  <div class="align-right"> 
    {{ info }} 
  </div>
  <div *ngIf="module" class="assunto-modulo">
    {{module}}
  </div>
  <h1 *ngIf="title" class="titulo-tela">
    {{title}}
  </h1>
  <div *ngIf="subtitle" class="subtitulo">
    {{subtitle}}
  </div>
</div>

`,
  styles: [`.convenio-bar{font-family:OpenSans-Regular,sans-serif;color:#454546;margin:0;padding-top:40px;padding-left:40px;background:#f8f9fa}.convenio-bar .assunto-modulo{font-size:14px;text-transform:uppercase;letter-spacing:.75px;margin-bottom:0}.convenio-bar .titulo-tela{color:#0a6aa6;font-size:22px;margin-top:0;margin-bottom:-1px}.convenio-bar .subtitulo{font-size:14px;margin-bottom:0;letter-spacing:.25px;margin-top:0;padding-bottom:20px}`]
})
export class TitleComponent implements OnInit {

  @Input() module = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() info = '';

  constructor() { }

  ngOnInit() {
  }
}
