import { Roles } from '../model/roles.model';
import { Profiles } from '../model/profiles.model';

export class User {
  name: string;
  roles?: Roles;
  profiles?: Profiles; 

  constructor(name: string, roles?: Roles, profiles?: Profiles) {
    this.name = name;
    this.roles = roles;
    this.profiles = profiles;
  }
}