import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputMaskService {

  constructor() { }

  public maskInput(input: string, mask: string): string {
    let value = input.replace(/\D/g, '');

    const pad = mask.replace(/\D/g, '').replace(/9/g, '_');
    const maskedValue = value + pad.substring(0, pad.length - value.length);

    let maskedValuePos = 0;
    value = '';

    for (let i = 0; i < mask.length; i++) {
      if (Number.isNaN(parseInt(mask.charAt(i)))) {
        value += mask.charAt(i);
      } else {
        value += maskedValue[maskedValuePos++];
      }
    }

    if (value.indexOf('_') > -1) {
      value = value.substr(0, value.indexOf('_'));
    }

    return value;
  }

}
