import { Directive, ElementRef, HostListener, OnInit, } from '@angular/core';

@Directive({ selector: '[siconvLowerCase]' })
export class LowerCaseDirective implements OnInit {

  constructor(
    private el: ElementRef,
  ) { }

  public ngOnInit(): void { }

  @HostListener('input', ['$event'])
  public onInputChange(event): void {
      const initalValue = this.el.nativeElement.value;
      if (initalValue != null && initalValue != '') {
        const modifiedValue = initalValue.toLowerCase();
        if (initalValue !== modifiedValue) {
          event.stopPropagation();
          this.setValue(modifiedValue);
        } 
      }
  }

  private setValue(value: string) {
    this.el.nativeElement.value = value;
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }
}
