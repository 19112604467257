import { NgModule } from '@angular/core';
import { FileComponent } from './components/file.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SiconvDirectivesModule } from '../../directives/public_api';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SiconvDirectivesModule
  ],
  declarations: [
    FileComponent,
  ],
  exports: [
    FileComponent
  ]
})
export class SiconvFileModule {}
