import { NgModule } from '@angular/core';
import { SubFieldsetComponent } from './components/sub-fieldset.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SubFieldsetComponent,
  ],
  exports: [
    SubFieldsetComponent
  ]
})
export class SiconvSubFieldsetModule {}
